import ContentLoader, { Facebook, List } from "react-content-loader";

const Loader = () => (
  <ContentLoader viewBox="0 0 380 70">
    {/* Only SVG shapes */}
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>
);
const FilterCatagoryLoader = () => (
  <ContentLoader viewBox="0 0 380 70">
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  </ContentLoader>
);

const NavbarLoader = () => {
  <ContentLoader
    speed={2}
    width={200}
    height={600}
    viewBox="0 0 200 500"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>;
};
const ImageLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={460}
    viewBox="0 0 400 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="384" height="400" />
  </ContentLoader>
);





const ProductLoader = (props) => (
  <ContentLoader
    speed={2}
    width={300}
    height={460}
    viewBox="0 0 400 460"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
    {...props}
  >
    <rect x="6" y="367" rx="2" ry="2" width="140" height="10" />
    <rect x="7" y="386" rx="2" ry="2" width="288" height="21" />
    <rect x="0" y="60" rx="2" ry="2" width="400" height="292" />
    <rect x="7" y="418" rx="2" ry="2" width="276" height="13" />
  </ContentLoader>
);

const AddressLoader = (props) => (
  <ContentLoader
    speed={2}
    width={500}
    height={224}
    viewBox="0 0 476 124"
    backgroundColor="#9e9a9a"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="2" y="37" rx="3" ry="3" width="88" height="6" />
    <rect x="353" y="33" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
  </ContentLoader>
);

const ProductDetailsLoader = (props) => (
  <ContentLoader
    speed={2}
    width={650}
    height={460}
    viewBox="0 0 650 460"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
    {...props}
    style={{ width: "100%", height: "100%" }}
  >
    <rect x="9" y="11" rx="2" ry="2" width="400" height="292" />
    <rect x="428" y="16" rx="0" ry="0" width="154" height="9" />
    <rect x="429" y="44" rx="0" ry="0" width="144" height="13" />
    <rect x="439" y="207" rx="0" ry="0" width="139" height="17" />
    <rect x="437" y="252" rx="0" ry="0" width="138" height="38" />
    <rect x="436" y="116" rx="0" ry="0" width="138" height="38" />
  </ContentLoader>
);

const MainBanner = (props) => (
  <ContentLoader
    speed={2}
    width={1440}
    height={480}
    viewBox="0 0 1440 480"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
    {...props}
  >
    <rect x="3" y="12" rx="2" ry="2" width="1440" height="400" />
  </ContentLoader>
);
const OrderListLoader = (props) => (
  <ContentLoader
    speed={2}
    width={700}
    height={150}
    viewBox="0 0 500 100"
    backgroundColor="#9e9a9a"
    foregroundColor="green"
    {...props}
  >
    <rect x="2" y="37" rx="3" ry="3" width="88" height="4" />
    <rect x="353" y="33" rx="3" ry="3" width="52" height="4" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="4" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="4" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="4" />
  </ContentLoader>
);

const ReturnListLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1440}
    height={480}
    viewBox="0 0 1440 480"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
    {...props}
  >
    <rect x="30" y="31" rx="0" ry="0" width="133" height="151" /> 
    <rect x="184" y="41" rx="0" ry="0" width="250" height="21" /> 
    <rect x="186" y="82" rx="0" ry="0" width="336" height="15" /> 
    <rect x="187" y="116" rx="0" ry="0" width="268" height="12" />
  </ContentLoader>
)

const SingleLine = (props) => (
  <ContentLoader 
    speed={2}
    width={300}
    height={100}
    viewBox="0 0 300 100"
    backgroundColor="#cfcece"
    foregroundColor="#ebebeb"
    {...props}
  >
    <rect x="160" y="41" rx="0" ry="0" width="250" height="25" /> 
    <rect x="24" y="41" rx="0" ry="0" width="119" height="25" />
  </ContentLoader>
)

export default MainBanner;

export {
  SingleLine,
  ReturnListLoader,
  FilterCatagoryLoader,
  Loader,
  NavbarLoader,
  List,
  ImageLoader,
  ProductLoader,
  ProductDetailsLoader,
  MainBanner,
  AddressLoader,
  OrderListLoader,
};
