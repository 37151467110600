import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import { hideLoader } from "../../Features/Loader/Loader";
import { Grid } from "@mui/material";
import "../Home/Trendy/Trendy.css";
import "../Account/AccountManager.css";
import { useParams } from "react-router-dom";
import { cancelOrder } from "../../Api/Order";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { showLoader } from "../../Features/Loader/Loader";
import { showToast } from "../Toast/ShowToast";
import { useNavigate } from "react-router-dom";
import AccountGoback from "../Account/AccountGoback";
function OrderCancel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  console.log("dbjhds", selected);
  const { OrderNum } = useParams();
  console.log("ibicvv", OrderNum);
  const scrollToTop = () => {
    if(selected){
      mutate(OrderNum, selected);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }else{
      showToast("Please Add the reason", "info");
    }
   
  };

  const { isLoading, isSuccess, error, mutate } = useMutation({
    mutationFn: cancelOrder,
    onMutate: () => {
      // showToast("Login Process Started", 'success');
      dispatch(showLoader());
    },
    onSuccess: (ddata) => {
      // console.log('dataasda',ddata)
      if (ddata.message) {
        showToast("Order Canceled", "success");
      } else {
        showToast(" Faild Try again later", "error");
      }
      dispatch(hideLoader());
      navigate("/");
    },
    onError: (err) => {
      var message = "Shome Thing Went WRONG contact Admin";

      if (err.response.data) {
        message = err.response.data;
      }
      console.error(message);

      // console.log('error',err.response.data);
      showToast(message, "errror");
    },
  });

  const selectedValue = (e) => {
    setSelected(e.target.value);
    console.log("hfbhdv", e.target.value);
  };
  return (
    <Grid
      className="container"
      marginTop={8}
      gap={3}
      container
      direction="column"
    >
    <AccountGoback data="Cancel Order"/>
      <Grid>
        <Accordion
        expanded
          style={{ backgroundColor: "#11824421" }}
          disableGutters
          elevation={0}
          
        >
          <AccordionSummary
          
            //   expandIcon={<IoIosArrowDown size={20} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h5 className="filterHeading">1. Easy Cancellation</h5>
          </AccordionSummary>

          <AccordionDetails className="accordianDetails_for_easy_cancel">
            <Grid paddingTop={2} justifyContent="space-between" container>
              <Grid className="order_cancel_reason">
                <p className="bold">Reason for Cancellation*</p>
                <select
                  //
                  className=" select_for_easy_cancel  form-select form-select-sm bg-transparent fs-13"
                  name="store-currency"
                  onChange={selectedValue}
                >
                  <option
                    className="footer-select__option"
                    value="Changed My Mind"
                  >
                    Changed My Mind
                  </option>
                  <option
                    className="footer-select__option"
                    value="Found a Better Price Elsewhere"
                  >
                    Found a Better Price Elsewhere
                  </option>
                  <option
                    className="footer-select__option"
                    value="Ordered the Wrong Item"
                  >
                    Ordered the Wrong Item
                  </option>
                  <option
                    className="footer-select__option"
                    value="Item is No Longer Needed"
                  >
                    Item is No Longer Needed
                  </option>
                </select>
              </Grid>
              <Grid item>
               
                <Link
                  // to="/account/easycancellation"
                  onClick={scrollToTop}
                  className="order_button_cancel_confirm"
                >
                  Confirm
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          style={{ backgroundColor: "#11824421" }}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            //   expandIcon={<IoIosArrowDown size={20} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h5 className="filterHeading">2. REFUND MODES</h5>
          </AccordionSummary>

          <AccordionDetails className="accordianDetails_for_easy_cancel">
            <Grid paddingTop={2} justifyContent="space-between" container>
              <Grid className="order_cancel_reason">
                <p className="bold">Reason for Cancellation*</p>
                <select
                  //
                  className=" select_for_easy_cancel  form-select form-select-sm bg-transparent fs-13"
                  name="store-currency"
                >
                  <option
                    className="footer-select__option"
                    value="ChangedMyMind"
                  >
                    Changed My Mind
                  </option>
                  <option
                    className="footer-select__option"
                    value=" Found a Better Price Elsewhere"
                  >
                    Found a Better Price Elsewhere
                  </option>
                  <option
                    className="footer-select__option"
                    value="Ordered the Wrong Item"
                  >
                    Ordered the Wrong Item
                  </option>
                  <option
                    className="footer-select__option"
                    value="Item is No Longer Needed"
                  >
                    Item is No Longer Needed
                  </option>
                </select>
              </Grid>
              <Grid item>
                <Link
                  onClick={scrollToTop}
                  className="order_button_cancel_confirm"
                >
                  Confirm
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

export default OrderCancel;
