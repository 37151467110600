import axios from "axios";
import { END_POINTS } from "../Api/Config/EndPoint";
import { userDataGet } from "./Local";
// console.log("userDataGet", userDataGet);
// console.log("gfhvbhv", userDataGet("user_id"));

const addwish = async (product) => {
  const payload = {
    ecom_WL_CustId: userDataGet("user_id"),
    ecom_WL_IM_Id: product.IM_ID,
    ecom_WL_CreatedBy: "cutomer",
    ecom_WL_CreatedAt: "2024-09-02T06:36:26.993Z",
    ecom_WL_UpdatedBy: "cutomer",
    ecom_WL_UpdatedAt: "2024-09-02T06:36:26.993Z",
  };

  try {
    const response = await axios.post(END_POINTS.ADD_WISHLIST, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("fbhjbvh", response.data);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    throw error;
  }
};

const GetWishList = async () => {
  let payload = {
    customerId: userDataGet("user_id"),
  };

  console.log("hehehe", payload);
  try {
    const response = await axios.get(
      END_POINTS.CUSTOMER_WISHLIST,
      { params: payload },
      {
        header: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const removewishlisttt = async (product,EcomID) => {


  const payload = {
    "Ecom_WL_Id": EcomID,
     "ecom_WL_CustId":  userDataGet("user_id"),
     "ecom_WL_IM_Id": product.IM_ID,
     "ecom_WL_CreatedBy": "string",
     "ecom_WL_CreatedAt": "2024-09-02T06:36:26.993Z",
     "ecom_WL_UpdatedBy": "string",
     "ecom_WL_UpdatedAt": "2024-09-02T06:36:26.993Z"
   };


  console.log("fjvbbvj", payload);
  try {
    const response = await axios.post(END_POINTS.REMOVE_WISHLIST, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("fbhjbvh", response.data);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    throw error;
  }
};

// const removewishlist = async (product) => {
//   console.log("fjvsssdctybvj", product);

//   const removingwishlistData ={
//     "Ecom_WL_Id": 1,
//      "ecom_WL_CustId":  userDataGet("user_id"),
//      "ecom_WL_IM_Id": product.IM_ID,
//      "ecom_WL_CreatedBy": "string",
//      "ecom_WL_CreatedAt": "2024-09-02T06:36:26.993Z",
//      "ecom_WL_UpdatedBy": "string",
//      "ecom_WL_UpdatedAt": "2024-09-02T06:36:26.993Z"
//    }
//   try {
//     const response = await axios.post(END_POINTS.REMOVE_WISHLIST, removingwishlistData, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     console.log("fbhjbvh", response.data);

//     return response.data;
//   } catch (error) {
//     console.log("ERROR", error);
//     throw error;
//   }
// };

export { addwish, GetWishList, removewishlisttt };
