import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../Features/Cart/cartSlice";
import AuthSlice from '../Features/Authentication/Authentication'
import Loader from "../Features/Loader/Loader";
import Currency from "../Features/Currency/Currency";
import Filter from '../Features/Filter/Filter'
import shippingAddress from '../Features/ShippingAddress/ShippingAddress'
import wishlistReducer from '../Features/Wishlist/WishSlice'; 
import UserRegistration from '../Features/UserRegistration/UserRegistration';

const logger = store => next => action => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

const store = configureStore({
  reducer: {
    Loader:Loader,
    cart: cartSlice,
    Auth:AuthSlice,
    Currency:Currency,
    Filter:Filter,
    shippingAddress: shippingAddress,
    wishlist: wishlistReducer,
    UserRegistration:UserRegistration
  },
  middleware: (getDefaultMiddleware) =>getDefaultMiddleware().concat(logger),
});

export default store;
