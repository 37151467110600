import { createSlice } from "@reduxjs/toolkit";

const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;
const persistedToken = localStorage.getItem("hytoken") || null;

const initialState = {
  user: persistedUser,
  token:persistedToken
};


const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    loginSucess(state, action) {
      console.log('actionpay',action.payload);
      state.user=action.payload.user;
      state.token=action.payload.token;

      console.log('actionuse',state.user);
      localStorage.setItem("hyuser", JSON.stringify(state.user));
      localStorage.setItem("hytoken",  state.token);

    },
    logout(state) {
      state.user = null;
      state.token = null;
      console.log('logout');
      localStorage.removeItem("hyuser");
      localStorage.removeItem("hytoken");
    },

  },
});

export const {
    loginSucess,
    logout,
} = AuthSlice.actions;



export default AuthSlice.reducer;