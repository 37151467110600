import { createSlice } from "@reduxjs/toolkit";
import {removeCartAsync,addToCartAsync,updateCartAsync} from '../Cart/cartSlice'

    const initialState = {
        isLoading: false,
      };
  
  const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
      showLoader: (state) => {
        console.log('loader show');
        state.isLoading = true;
      },
      hideLoader: (state) => {
        console.log('loader hide');
        state.isLoading = false;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(removeCartAsync.pending, (state, action) => {
        // console.log('RemoveCart',action);
        state.isLoading = true;
        // state.value = action.payload;
      })
      .addCase(removeCartAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeCartAsync.rejected, (state, action) => {
        state.isLoading = false;
      }); 

      //  builder.addCase(addToCartAsync.pending, (state, action) => {
      //   state.isLoading = true;
      // })
      // .addCase(addToCartAsync.fulfilled, (state, action) => {
      //   state.isLoading = false;
      // })
      // .addCase(addToCartAsync.rejected, (state, action) => {
      //   state.isLoading = false;
      // });

       builder.addCase(updateCartAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateCartAsync.rejected, (state, action) => {
        state.isLoading = false;
      });

    },
  });
  
  export const { showLoader, hideLoader } = loaderSlice.actions;
  export default loaderSlice.reducer;