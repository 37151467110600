// const reCAPTCHA_site_key='6LcjgDIqAAAAAPICL_1CMKagChIi91o4QSgjtzrh'
// const reCAPTCHA_secret_key='6LcjgDIqAAAAAJKuzBOQz87EYEvtCzSgK1lcWVOg'

const reCAPTCHA_site_key='6LedrTIqAAAAAA1teHNYJN9y2rHFUqUB-3_51Z74'
const reCAPTCHA_secret_key='6LedrTIqAAAAANPb7eam3k_HJMCvWO2hodlOb_PY'
const map_key='AIzaSyAWCpfBNEAbt0HC4kGA5dQr3TALX8tzDbM';

const COMPANY_lat='3.12763';
const COMPANY_lan='101.469362';
const accesstoken='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';

const IMAGE_URL_BASE_PATH='http://202.21.38.173:9095/images/itemimages/';


const STRIP_PUBLIC='';

export {
    STRIP_PUBLIC,
    reCAPTCHA_site_key,
    reCAPTCHA_secret_key,
    COMPANY_lat,
    COMPANY_lan,
    IMAGE_URL_BASE_PATH
}