import React, { useState } from "react";
import "./AdditionalInfo.css";

import user1 from "../../../Assets/Users/user1.jpeg";
import user2 from "../../../Assets/Users/user2.jpeg";

import { FaStar } from "react-icons/fa";
import Rating from "@mui/material/Rating";

const AdditionalInfo = ({ title, description, tags, rating, sku, reviews, weight,
  dimensions,
  availabilityStatus,
  shippingInformation,
  warrantyInformation,
  stock, }) => {
  const [activeTab, setActiveTab] = useState("aiTab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={`full-${i}`} color="#FEC78A" size={10} />);
    }
    if (halfStar) {
      stars.push(<FaStar key={`half`} color="#FEC78A" size={10} />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaStar key={`empty-${i}`} color="#B4B4B8" size={10} />);
    }

    return stars;
  };

  return (
    <div className="productAdditionalInfo">
      <div className="productAdditonalInfoContainer">
        <div className="productAdditionalInfoTabs">
          <div className="aiTabs">
            <p
              onClick={() => handleTabClick("aiTab1")}
              className={activeTab === "aiTab1" ? "aiActive" : ""}
            >
              Description
            </p>
            <p
              onClick={() => handleTabClick("aiTab2")}
              className={activeTab === "aiTab2" ? "aiActive" : ""}
            >
              Additional Information
            </p>
            {/* <p
              onClick={() => handleTabClick("aiTab3")}
              className={activeTab === "aiTab3" ? "aiActive" : ""}
            >
              Reviews ({reviews.length})
            </p> */}
          </div>
        </div>
        <div className="productAdditionalInfoContent">
          {/* Tab1 */}

          {activeTab === "aiTab1" && (
            <div className="aiTabDescription">
              <div className="descriptionPara">
                <h3>{title}</h3>
                <p>
                  {description}
                </p>
              </div>
              {/* <div className="descriptionParaGrid">
                <div className="descriptionPara">
                  <h3>Why choose product?</h3>
                  <p>
                    <ul>
                      <li>Creat by cotton fibric with soft and smooth</li>
                      <li>
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </li>
                      <li>Downloadable/Digital Products, Virtual Products</li>
                    </ul>
                  </p>
                </div>
                <div className="descriptionPara">
                  <h3>Sample Number List</h3>
                  <p>
                    <ol>
                      <li>Creat by cotton fibric with soft and smooth</li>
                      <li>
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </li>
                      <li>Downloadable/Digital Products, Virtual Products</li>
                    </ol>
                  </p>
                </div>
              </div> */}
              {/* <div className="descriptionPara">
                <h3>Lining</h3>
                <p style={{ marginTop: "-10px" }}>
                  100% Polyester, Main: 100% Polyester.
                </p>
              </div> */}
            </div>
          )}

          {/* Tab2 */}

          {activeTab === "aiTab2" && (
            <div className="aiTabAdditionalInfo">
              <div className="additionalInfoContainer">
                <h6>Weight</h6>
                <p> {weight}</p>
              </div>
              <div className="additionalInfoContainer">
                <h6>Dimensions</h6>
                <p> {dimensions.width} x {dimensions.height} x {dimensions.depth} cm</p>
              </div>
              <div className="additionalInfoContainer">
                <h6>Warranty Information</h6>
                <p>{warrantyInformation}</p>
              </div>
              <div className="additionalInfoContainer">
                <h6>Shipping Information</h6>
                <p>{shippingInformation}</p>
              </div>
            </div>
          )}

          {/* Tab3 */}

          {activeTab === "aiTab3" && (
            <div className="aiTabReview">
              <div className="aiTabReviewContainer">
                <h3>Reviews</h3>
                <div className="userReviews">
                  {reviews.map((review, index) => (
                    <div key={index} className="userReview">
                      <div className="userReviewImg">
                        <img src={user2} alt="User" />
                      </div>
                      <div className="userReviewContent">
                        <div className="userReviewTopContent">
                          <div className="userNameRating">
                            <h6>{review.reviewerName}</h6>
                            <div className="userRating">
                              {renderStars(review.rating)}
                            </div>
                          </div>
                          <div className="userDate">
                            {/* Format date as needed */}
                            <p>{new Date(review.date).toLocaleDateString()}</p>
                          </div>
                        </div>
                        <div className="userReviewBottomContent">
                          <p>{review.comment}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
                <div className="userNewReview">
                  <div className="userNewReviewMessage">
                    <h5>
                      Be the first to review “Lightweight Puffer Jacket With a
                      Hood”
                    </h5>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                  </div>
                  <div className="userNewReviewRating">
                    <label>Your rating *</label>
                    <Rating name="simple-controlled" size="small" />
                  </div>
                  <div className="userNewReviewForm">
                    <form>
                      <textarea cols={30} rows={8} placeholder="Your Review" />
                      <input
                        type="text"
                        placeholder="Name *"
                        required
                        className="userNewReviewFormInput"
                      />
                      <input
                        type="email"
                        placeholder="Email address *"
                        required
                        className="userNewReviewFormInput"
                      />
                      <div className="userNewReviewFormCheck">
                        <label>
                          <input type="checkbox" placeholder="Subject" />
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </div>

                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
