import { END_POINTS } from "./Config/EndPoint";
import { api } from "./Config/AxioConfig";
import { userDataGet } from "./Local";

const CheckOut = async (payloadData) => {
  try {
    const response = await api.post(END_POINTS.CHECK_OUT, payloadData);
    console.log(response);
    // return response;

    return response.data;  
  } catch (error) { 
    console.error("ERROR - GROUP", error);
    throw error; 
  
  }
};

const getOrderBySearch=async (searchq)=>{
  
  let payload = {
    ecom_Cust_id: userDataGet("user_id"),
    ecom_Order_id:'0',
    ecom_Order_Number:searchq,
    pageSize: 1,
    pageNumber: 1,
  };

  try {
    const response = await api.post(END_POINTS.GET_SINGLE_ORDERS, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("ERROR - GROUP", error);
  }
}

const getSingleOrder = async (OrderId) => {
  let payload = {
    ecom_Cust_id: userDataGet("user_id"),
    ecom_Order_id: OrderId,
    ecom_Order_Number:'0',
    pageSize: 1,
    pageNumber: 1,
  };

  try {
    const response = await api.post(END_POINTS.GET_SINGLE_ORDERS, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("ERROR - GROUP", error);
  }
};

const getOrders = async (pageSize, pageNumber) => {
  let payload = {
    ecom_Cust_id: userDataGet("user_id"),
    ecom_Order_id: 0,
    pageSize: pageSize,
    pageNumber: pageNumber,
  };

  console.log("getOrders", payload);

  try {
    const response = await api.post(END_POINTS.GET_SINGLE_ORDERS, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // alert('sajfsafkj');
    console.log("getOrdersss", response.data);
    return response.data;
  } catch (error) {
    console.error("ERROR - GROUP", error);
  }
};

// const cancelOrder =async (reason)=>{
//    let payload ={
//     "ecom_Cust_id": userDataGet('user_id'),
//     "ecom_Order_Number": "string",
//     "reason": "string",
//   }}

const cancelOrder = async (OrderNum,selected) => {
  let payload = {
    ecom_Cust_id: userDataGet("user_id"),
    ecom_Order_Number: OrderNum,
    reason: selected,
  };

  console.log("gbfg", payload);

  try {
    const response = await api.post(END_POINTS.CANCEL_ORDER, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // alert('sajfsafkj');
    console.log("gfbngh", response.data);
    return response.data;
  } catch (error) {
    console.error("ERROR - GROUP", error);
  }
};


const getOrderStatus= async (orderNumber)=>{

  try {
    const response = await api.get(END_POINTS.ORDER_STATUS, {
      params: { order_number: orderNumber },
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(`Error fetching order status: ${error}`);
    throw error; 
  }
}
export { CheckOut, getOrders, getSingleOrder ,cancelOrder,getOrderStatus,getOrderBySearch};
