import { END_POINTS } from "./Config/EndPoint";
import {api} from './Config/AxioConfig';
import {userDataGet} from './Local'

const AddandupdateCart=async (payloadData) =>{
console.log('-------------API Startes sdff------------');
console.log('FinalPayloade',payloadData);

let FinalPayloade={
  "Cart_Customer_Id":userDataGet('user_id'),
  "Cart_Status": null,
  "Cart_CreatedAt": null,
  "Cart_CreatedBy": "admin",
  "Cart_UpdatedAt": null,
  "Cart_UpdatedBy": "admin",
};

if(!Array.isArray(payloadData)){

  let cartdtl=[];
  cartdtl.push(payloadData);
  FinalPayloade['CartDtls']=cartdtl;
}else{

  FinalPayloade['CartDtls']=payloadData;


}

console.log('FinalPayloade',FinalPayloade);

  try{
    const response = await api.post(END_POINTS.USER_CART,FinalPayloade);
    console.log(response);
    return response.data;

    } catch (error) {
      console.error('ERROR - GROUP',error);
    }
}
const RemoveCartDB=async (CartId,productId) =>{
  console.log('-------------API Startes sdff------------');
 
    let payloadData={
    "cartId": CartId,
    "productId":(productId).toString()
    }

  console.log('payloadDatae',payloadData);

  try{
    const response = await api.post(END_POINTS.CART_REMOVE,payloadData);
    console.log('RemoveCartDB',response);
    return response.data;

    } catch (error) {
      console.error('ERROR - GROUP',error);
    }
}

const UpdateCart=async (productId,qty,price)=>{

    let payloadData={
      "Cart_Customer_Id":userDataGet('user_id'),
      "Cart_CreatedBy": "admin",
      "Cart_UpdatedBy": "admin",
      "cartDtls": [
          {
              "CDtl_Product_Id":productId,
              "CDtl_Variant_Id":"0",
              "CDtl_Quantity":qty,
              "CDtl_Price":price,
              "CDtl_CreatedBy": "admin",
              "CDtl_UpdatedBy": "admin"
          }
      ]
  };

  console.log('updatecart',payloadData);
//   {
//     "Cart_Customer_Id": "14fdb324-a6a8-4fb0-a421-90d2e839add6",
//     "Cart_CreatedBy": "admin",
//     "Cart_UpdatedBy": "admin",
//     "cartDtls": [
//         {
//             "CDtl_Product_Id": "313",
//             "CDtl_Variant_Id": "0",
//             "CDtl_Quantity": 20,
//             "CDtl_CreatedBy": "admin",
//             "CDtl_UpdatedBy": "admin"
//         }
//     ]
// }

  try{
    const response = await api.post(END_POINTS.USER_CART,payloadData);
    console.log(response);
    return response.data;
  } catch (error) {
      console.error('ERROR - GROUP',error);
  }

}


const getCartItems=async()=>{
  // console.log('user dat',LocalGet('user_id'));
  // console.log('user dat',userData());

  let payload={
   "customerId":userDataGet('user_id'),
  }

  try{
      const response = await api.get(END_POINTS.CART_GET,{params :payload});
    console.log(response);
    return response.data;

    } catch (error) {
      console.error('ERROR - GROUP',error);
    }

}

export  {
    AddandupdateCart,
    RemoveCartDB,
    getCartItems,
    UpdateCart
};