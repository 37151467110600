import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencySymbol: 'RM', // Set the default currency symbol to USD
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrencySymbol: (state, action) => {
      state.currencySymbol = action.payload;
    },
  },
});

export const { setCurrencySymbol } = currencySlice.actions;
export const selectCurrency = (state) => state.Currency.currencySymbol;
export default currencySlice.reducer;