import { createSlice } from "@reduxjs/toolkit";
import { GetWishList } from "../../Api/WishList";

const initialState = {
  count: 0,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    getCount: (state) => {
   
      state.count = Math.random();
    },
    
  },
});

export const { addToWishlist, removeFromWishlist, resetWishlist, getCount,  } =
  wishlistSlice.actions;
export const GetWishListCount = (state) => state.wishlist.count;

export default wishlistSlice.reducer;


