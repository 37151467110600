
import Return from "../Components/Return/Return";
import VerificationOtp from "../Components/Verification/verification";


const Verification=()=>{
    return  (
        <VerificationOtp/>
    )
}

export default Verification;