import { END_POINTS } from "./Config/EndPoint";
import axios, {isCancel, AxiosError} from 'axios';

const getGuestToken=async ()=>{
      try{
        const response = await axios.post(END_POINTS.GUEST_TOKEN,{},{
          headers: {
              'Content-Type': 'application/json',
          }});
          console.log('axiofuntoekn',response.data);
          return response.data;
      } catch (error) {
        console.log('ERROR',error);
        throw error;
      } 
    
}

export {
  getGuestToken
}