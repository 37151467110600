import { END_POINTS } from "./Config/EndPoint";
import {api} from './Config/AxioConfig';
import {userDataGet} from './Local'


 const fetchProducts = async (pageNumber,pageSize=10,sortby) => {
    try {

      let payload = {
        // "item_id":0,
        // "item_Category":0,
        // "item_Name": "string",
        // "item_Group": 0,
        "pageNumber":pageNumber,
        "pageSize":pageSize,
        // "item_Brand": 0,
        "sortBy": sortby,
      };

      if(userDataGet('user_id')){
        payload["customer_ID"]=userDataGet('user_id');
      }

      const response = await api.post(END_POINTS.PRODUCTS,payload);
      console.log('full products',response);
      if (response.data.products.length > 0) {

        return response.data;
      }else{
              
      console.error('length NOt found ');
      }
    } catch (error) {
  
      console.error('ERROR - PRODUCT',error);
    }
  }
    const fetchProductsForShop = async (payload) => {
    try {


      console.log('payloadData 4',payload);
      if(userDataGet('user_id')){
        payload["customer_ID"]=userDataGet('user_id');
      }

      const response = await api.post(END_POINTS.PRODUCTS,payload);
      console.log('full products',response);
      if (response.data.products.length > 0) {

        return response.data;
      }else{
              
      console.error('length NOt found ');
      }
    } catch (error) {
  
      console.error('ERROR - PRODUCT',error);
    }
  }

  const fetchProductsByName = async (ItemName,pageNumber,pageSize=10) => {
    try {

      let payload = {
        "item_Name":ItemName,
        "pageNumber":pageNumber,
        "pageSize":pageSize,
      };

      if(userDataGet('user_id')){
        payload["customer_ID"]=userDataGet('user_id');
      }
      console.log('payloade',payload);

      const response = await api.post(END_POINTS.PRODUCTS,payload);
      console.log('full products',response);
      if (response.data.products.length > 0) {

        return response.data;
      }else{
              
      console.error('length NOt found ');
      }
    } catch (error) {
  
      console.error('ERROR - PRODUCT',error);
    }
  }

  const fetchProductsById=async (ProductID) =>{

    let payload = {
      "item_id":ProductID
       };

    if(userDataGet('user_id')){
      payload["customer_ID"]=userDataGet('user_id');
    }
  
    try{
      const response = await api.post(END_POINTS.SINGLE_PRODUCT,payload);
      console.log('response.data',response.data);
      
      if(response.data){
        return response.data;
      }else{
        return 'sf';
      }

       } catch (error) {
        console.error('ERROR - PRODUCT',error);
      }
  }

const FetchProductByCatagory=async (Catagorie_ids,pageNumber,pageSize=10,sortBy) =>{

  let payload = {
    "item_Category":Catagorie_ids,
    "pageNumber":pageNumber,
    "pageSize":pageSize,
    "sortBy":sortBy
  };

  if(userDataGet('user_id')){
    payload["customer_ID"]=userDataGet('user_id');
  }


  try{
    const response = await api.post(END_POINTS.PRODUCTS,payload);
    console.log('RESPONSIE',response.data);
    return response.data;

     } catch (error) {
      console.error('ERROR - PRODUCT',error);
    }

}


const FetchProductByGroup=async (group_id,pageNumber,pageSize) =>{

  let payload = {
    "item_Group": [group_id],
    "pageNumber":pageNumber,
    "pageSize":pageSize,
    "sortBy": "string"
  };

  if(userDataGet('user_id')){
    payload["customer_ID"]=userDataGet('user_id');
  }

  try{
    const response = await api.post(END_POINTS.PRODUCTS,payload);
    console.log('---------------------');
    // console.log(group_id);
    console.log('END_POINTS',response.data);
    console.log('---------------------');
    return response.data;

     } catch (error) {
      console.error('ERROR - PRODUCT',error);
    }

}


export {
  FetchProductByCatagory,
  fetchProducts,
  FetchProductByGroup,
  fetchProductsById,
  fetchProductsByName,
  fetchProductsForShop
};