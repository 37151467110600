import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Function to show toast notification
export const showToast = (message, type, autoClose = 2000) => {
  switch (type) {
    case "info":
      return toast.info(message, {
        autoClose: autoClose,
        position: "bottom-center",
      });
    case "success":
      return toast.success(message, {
        autoClose: autoClose,
        position: "bottom-center",
      });
    case "error":
      return toast.error(message, {
        autoClose: autoClose,
        position: "bottom-center",
      });
    case "warning":
      return toast.warn(message, {
        autoClose: autoClose,
        position: "bottom-center",
      });
    default:
      return toast(message, {
        autoClose: autoClose,
        position: "bottom-center",
      });
  }
};

export const showToastBottomCenter = (message, type, autoClose = 2000) => {
  switch (type) {
    case "info":
      return toast.info(message, {
        position: "bottom-center",
        autoClose: autoClose,
      });
    case "success":
      return toast.success(message, {
        position: "bottom-center",
        autoClose: autoClose,
      });
    case "error":
      return toast.error(message, {
        position: "bottom-center",
        autoClose: autoClose,
      });
    case "warning":
      return toast.warn(message, {
        position: "bottom-center",
        autoClose: autoClose,
      });
    default:
      return toast(message, {
        position: "bottom-center",
        autoClose: autoClose,
      });
  }
};
