import React, { useEffect, useState } from "react";
import "./OrderSummery.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useQuery } from "@tanstack/react-query";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { getOrders, getSingleOrder, getOrderStatus } from "../../Api/Order";
import { selectCurrency } from "../../Features/Currency/Currency";
import success from "../../Assets/success.png";
import { useParams, useNavigate } from "react-router-dom";
// import {formatDateTime} from '../../Lib/Lib';
import NoImage from "../../Assets/no_image.jpg";
import { IMAGE_URL_BASE_PATH } from "../../App/GlobalEnv";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import { dateTimeFormat } from "../../Lib/Lib";
import Avatar from "@mui/material/Avatar";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { color } from "framer-motion";

import { dateTimeFormate } from "../../Lib/Lib";
import AccountGoback from "../Account/AccountGoback";
import { Grid } from "@mui/material";

export default function OrderSummery({ orderID, isCheckoutpage }) {
  const { paramOrderid } = useParams();
  let Navigate = useNavigate();

  const [steps, setsteps] = useState([
    "Ordered",
    "In Progress",
    "packed",
    "Dispatched",
    "Out for Delivery",
    "Delivered ",
  ]);

  // var steps = [
  //   "Ordered",
  //   "In Progress",
  //   "packed",
  //   "Shipped",
  //   "Dispatched",
  //   "Out for Delivery",
  //   "Delivered ",
  // ];
  const stepsIn = [
    "Ordered",
    "In",
    "packed",
    "Dispat",
    "Out",
    "Del",
  ];

  const CurrencySymbol = useSelector(selectCurrency);
  const [ActiveStep, setActiveStep] = useState([]);
  const finalid = paramOrderid ? paramOrderid : orderID;

  const {
    isPending,
    isError,
    data: OrderData,
    error,
  } = useQuery({
    queryKey: ["orderSummary", finalid],
    queryFn: async () => {
      const { orders } = await getSingleOrder(finalid);
      return orders[0];
    },
    staleTime: 0,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });

  const { data: orderStatus } = useQuery({
    queryKey: ["orderStatus", OrderData?.Ecom_CO_Order_NO],
    queryFn: async () => {
      const ordersSttus = await getOrderStatus(OrderData?.Ecom_CO_Order_NO);
      console.log("ordersSttus", ordersSttus);
      return ordersSttus;
    },
    enabled: !!OrderData?.Ecom_CO_Order_NO,
  });

  //   const formatDate = (date) => {
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const year = date.getFullYear();
  //     return `${day}/${month}/${year}`;
  //   };
  const CheckStats = (label) => {
    console.log('label',label);
    const checkit = (element) =>
      element?.Ecom_OS_Name?.toLowerCase().includes(label?.toLowerCase());

    return orderStatus?.find(checkit);
  };
  useEffect(function () {
    // console.log('finalid',finalid);
    // console.log("finalid", "p", paramOrderid);
    console.log("OrderData", "p", orderStatus);

    if (CheckStats("Cancelled")) {
      console.log("OrderData", "p", CheckStats("Cancelled"));
      setsteps(["Ordered", "Cancelled"]);
    }
    // steps?.forEach((element, index) => {
    //   let check = orderStatus?.some((elementinner) => {
    //     elementinner?.Ecom_OS_Name?.includes(element);
    //   });

    //   if (check) {
    //     setActiveStep((prev) => prev + 1);
    //   }
    // });

    // const maxIndices = [];

    // orderStatus?.forEach((element) => {
    //   var small=element.Ecom_OS_Name.toLowerCase();
    //   const index = stepsIn.findIndex((x) =>{
    //     console.log("OrderData ---------------------");
    //     console.log("OrderData",x.toLowerCase());
    //     console.log("OrderData",small);
    //     console.log("OrderData",x.toLowerCase().includes(small));
    //     console.log("//OrderData ---------------------")

    //     return x.toLowerCase().includes(small)
    //   }

    //   );
    //   maxIndices.push(index);
    // });

    // console.log("OrderData", Math.max.apply(null, listof));

    // var maxr=[];
    // orderStatus?.forEach((element,index) => {

    //   console.log("OrderData",x.toLowerCase());
    //   console.log("OrderData",element?.Ecom_OS_Name);
    //   console.log("OrderData",x.toLowerCase().includes(element?.Ecom_OS_Name));

    //   maxr.push(stepsIn.findIndex(x =>{

    //     return x.toLowerCase().includes(element?.Ecom_OS_Name);
    //   });
    // });
    // console.log("OrderData", "p",maxr);
    // console.log("OrderData", "p", Math.max(maxr));

    // console.log(Math.max(maxr));
  }, []);

  return (
    <>
      <Grid container direction="column" className="container" gap={2}>
        <Grid>
          <AccountGoback />
        </Grid>
        <Grid>
          <h1>Order Details</h1>
          <Grid container gap={1}>
            <strong> Ordered on</strong>
            {dateTimeFormate(OrderData?.Ecom_CO_createdat)}{" "}
            <strong> Order#</strong>
            {OrderData?.Ecom_CO_Order_NO}
          </Grid>
        </Grid>

        <Grid className="">
          {/* <p>{JSON.stringify(OrderData?.Shipment)}</p> */}
          <Grid>
            <Grid className="order_card_container">
              <Grid container justifyContent="space-between" gap={2}>
                <Grid>
                  <strong className="label">Shipping Address</strong>
                  <p>{OrderData?.Shipment?.Shipment_AddressLine1}</p>
                  <p>{OrderData?.Shipment?.Shipment_AddressLine2}</p>
                  <p>{OrderData?.Shipment?.Shipment_Landmark}</p>
                  <p>{OrderData?.Shipment?.Shipment_State}</p>
                </Grid>
                <Grid>
                  <strong className="label">Payment Methods</strong>
                  <p>{OrderData?.Ecom_CO_payment_method}</p>
                </Grid>
                <Grid>
                  <strong className="label">Order Summary</strong>
                  <Grid className="d-flex flex-column gap-1">
                    <p>
                      Item(s) Subtotal :{" "}
                      {OrderData?.Ecom_CO_subtotal_price &&
                        OrderData?.Ecom_CO_currency +
                          " " +
                          OrderData?.Ecom_CO_subtotal_price}{" "}
                    </p>
                    <p>
                      Shipping :{" "}
                      {OrderData?.Ecom_CO_shipping_price &&
                        OrderData?.Ecom_CO_currency +
                          " " +
                          OrderData?.Ecom_CO_subtotal_price}
                    </p>
                    <p>
                      {OrderData?.Ecom_CO_payment_method} on Delivery fee :{" "}
                      {OrderData?.Ecom_CO_subtotal_price &&
                        OrderData?.Ecom_CO_currency +
                          " " +
                          OrderData?.Ecom_CO_subtotal_price}{" "}
                    </p>
                    <p>
                      Grand Total :{" "}
                      {OrderData?.Ecom_CO_total_price &&
                        OrderData?.Ecom_CO_currency +
                          " " +
                          OrderData?.Ecom_CO_total_price}{" "}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Grid
            container
            justifyContent="center"
            className="order_card_container"
          >
            <Grid justifyContent="center">
              <Box sx={{ width: "50%" }}>
                <Stepper
                  style={{ color: "green" }}
                  sx={{
                    "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
                      color: "#f7941d",
                    },
                    "& .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed":
                      {
                        color: "#089247",
                      },
                    "& .MuiStepConnector-line": {
                      borderColor: "#dadada",
                    },

                    "& .MuiStepLabel-label": {
                      fontSize: 9,
                    },
                    // '& .Mui-completed':{
                    //   color:'red'
                    // }
                  }}
                  alternativeLabel
                >
                  {/* {steps?.map((label,index) => (
                      <Step key={label} completed={ 
                        CheckStats(stepsIn[index])
                        }>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))} */}

                  {OrderData?.Ecom_CO_fulfillment_status === "Cancelled"
                    ? ["Ordered", "Cancelled"]?.map((label, index) => {
                        const getCompletedStatus = CheckStats(label);
                        return (
                          <Step key={label} completed={getCompletedStatus}>
                            <StepLabel>
                              {label}
                              <br />
                              {getCompletedStatus?.Ecom_OS_Datetime
                                ? dateTimeFormate(
                                    getCompletedStatus?.Ecom_OS_Datetime
                                  )
                                : ""}
                            </StepLabel>
                          </Step>
                        );
                      })
                    : steps?.map((label, index) => {
                        const getCompletedStatus = CheckStats(stepsIn[index]);
                        return (
                          <Step key={label} completed={getCompletedStatus}>
                            <StepLabel>
                              {label}
                              <br />
                              {getCompletedStatus?.Ecom_OS_Datetime
                                ? dateTimeFormate(
                                    getCompletedStatus?.Ecom_OS_Datetime
                                  )
                                : ""}
                            </StepLabel>
                          </Step>
                        );
                      })}
                </Stepper>
              </Box>
            </Grid>
            <Grid className="row  my-3">
              {OrderData?.LineItems?.map((items) => (
                <div className="d-flex  col-12 col-sm-6 gap-2 p-2 justify-content-center">
                  <div className="d-flex flex-row gap-2">
                    <Avatar
                      variant="square"
                      sx={{ width: 100, height: 100 }}
                      src={
                        typeof items?.Product?.IM_PicturePath == "string"
                          ? IMAGE_URL_BASE_PATH + items?.Product?.IM_PicturePath
                          : // ? product.IM_PicturePath
                            NoImage
                      }
                    >
                      N
                    </Avatar>
                    <div className="d-flex flex-column gap-2">
                      <p>{items?.Product?.IM_ItemName}</p>
                      <p style={{ fontSize: "12px" }}>
                        {OrderData?.Ecom_CO_currency} {items.Ecom_Co_LI_price} *
                        qty {items.Ecom_Co_LI_quantity}
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {OrderData?.Ecom_CO_currency} {items.Ecom_Co_LI_price}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
