
import { showToast } from "../../Components/Toast/ShowToast";
import store from "../../App/store";
import { showLoader, hideLoader } from "../../Features/Loader/Loader";
import { addwish } from "../../Api/WishList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {ReturnRequest} from '../../Api/Return'

const useReturnMutate = () => {
    // const useQueryClientd = useQueryClient();
  
    return useMutation({
      mutationFn: ReturnRequest,
      onMutate: () => {
        store.dispatch(showLoader());
      },
      onSuccess: async (ddata) => {
        if (ddata.message) {
          showToast(ddata.message, "success");
        } else {
          showToast("Something went wrong", "error");
        }
        store.dispatch(hideLoader());

      },
      onError: (err) => {
        store.dispatch(hideLoader());
        console.log("error", err.response.data);
        showToast("Something went wrong", "error");
      },
  
      onSettled: (data, error) => {},
    });
  };

  export {
    useReturnMutate
  }