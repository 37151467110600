import { END_POINTS } from "./Config/EndPoint";
import { api } from "./Config/AxioConfig";
import { userDataGet } from "./Local";

const ReturnRequest = async (payload) => {
  try {
    console.log("payloade", payload);

    const response = await api.post(END_POINTS.RETURN, payload);
    if (response.data) {
      return response.data;
    } else {
      throw "some thing went wrong";
    }
  } catch (error) {
    throw error;
    console.error("ERROR - PRODUCT", error);
  }
};
const getReturn = async (ord_num) => {
  try {

    const response = await api.get(END_POINTS.RETURN_LIST, {
      params: { orderNumber: ord_num },
      headers: { "Content-Type": "application/json" },
    });

    if (response.data) {
      return response.data;
    } else {
      throw "some thing went wrong";
    }
  } catch (error) {
    throw error;
    console.error("ERROR - PRODUCT", error);
  }
};

export { ReturnRequest, getReturn };
