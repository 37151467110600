import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {
    price: {
      min: 0,
      max: 1000000,
      range: [0, 1000000],
    },
    brands: [],
    catagory: [],
  },
  filterChips: {},
};

const Filter = createSlice({
  name: "Filter",
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setFilterPrice(state, action) {
      state.filter.price = action.payload;

      console.log("rawfilter", state.filter);
    },

    setFilterBrand(state, action) {
      console.log("---------------setFilterBrand--------------------");
      console.log("setFilterBrand -1 ,", state.filter.brands);
      console.log("setFilterBrand -3", action.payload);
      state.filter.brands.push(action.payload);
      console.log("setFilterBrand -2 ,", state.filter.brands);
      console.log("---------------end setFilterBrand--------------------");
    },
    setFilterCat(state, action) {
      console.log("---------------setFilterBrand--------------------");
      console.log("setFilterBrand -1 ,", state.filter.brands);
      console.log("setFilterBrand -3", action.payload);
      state.filter.catagory.push(action.payload);
      console.log("setFilterBrand -2 ,", state.filter.brands);
      console.log("---------------end setFilterBrand--------------------");
    },
    setFilterChips(state, action) {
      const newFilterChips = Object.keys(action.payload).reduce((acc, key) => {
        acc[key] = action.payload[key];
        delete state.filterChips[key]; // remove existing property
        return acc;
      }, {});
      state.filterChips = { ...state.filterChips, ...newFilterChips };
      console.log("filterChips dsgdg", state.filterChips);
    },
    RemoveFilter(state, action) {
      const chipKey = action.payload;

      const chipIndex = chipKey.key;
      const chipValue = chipKey.value;

      switch (chipIndex) {
        case "minPrice":
          console.log("chipKey,", chipKey);
          state.filter.price.minPrice = 0;
          break;
        case "maxPrice":
          state.filter.price.maxPrice = 100000;
          break;
        case "rangeMax":
          state.filter.price.range = [state.filter.price.range[0],100000];
          break;
        case "rangeMin":
          state.filter.price.range = [0, state.filter.price.range[1]];
          break;
        case "brand":
          // console.log("insideBrandfile", chipValue);
          state.filter.brands = state.filter.brands.filter(
            (item) => item.IB_BrandName != chipValue
          );
          break;
        case "catagory":
          console.log("insidecatagoryfile", chipValue);
          state.filter.catagory = state.filter.catagory.filter(
            (item) => item.ICtg_CategoryName != chipValue
          );
          break;
        default:
          break;
      }
    },
    ClearFilter(state, action) {
      state.filter = initialState.filter;
    },
    RemoveFilterChips(state, action) {
      console.log("--------------Remove Chip------------------");
      console.log(state.filterChips);
      console.log(action.payload);

      // const newState = { ...state };

      // Handle removal of filter chips
      const chipKey = action.payload;

      const chipIndex = chipKey.key;
      const chipValue = chipKey.value;

      console.log("Remove Chip", chipKey);

      // Update the filter value based on the removed chip
      // Update the filter value based on the removed chip
      switch (chipIndex) {
        case "minPrice":
          console.log("chipKey,", chipKey);
          state.filter.price.minPrice = 0;
          break;
        case "maxPrice":
          state.filter.price.maxPrice = 1000000;
          break;
        case "rangeMax":
          state.filter.price.range = [state.filter.price.range[0], 100000];
          break;
        default:
          break;
      }

      if (chipIndex?.includes("brand")) {
        console.log(state.filter.Brands);
        state.filter.Brands = state.filter.Brands.filter(
          (item) => item.IB_BrandName != chipValue[1]
        );
      }

      console.log("-Remove Chip", chipKey);
      if (chipIndex in state.filterChips) {
        delete state.filterChips[chipIndex];
      }
      // switch (action.payload) {
      //   case 'minPrice':
      //     state.filter.price = { ...newState.filter.price, minPrice: 0 };
      //     console.log('state.filter', action.payload);
      //     break;
      //   case 'maxPrice':
      //     state.filter.price = { ...newState.filter.price, maxPrice: 100000 };
      //     break;
      //   default:

      //     break;
      // }
      // delete state.filterChips[action.payload];

      state.filterChips = { ...state.filterChips };
      console.log("filterChips dsgdg", state.filterChips);
      console.log("--------------END Remove Chip------------------");
    },
    getFilter(state, action) {},
    GenrateChips(state, action) {},
  },
});

export const {
  setFilter,
  setFilterPrice,
  setFilterBrand,
  setFilterChips,
  RemoveFilter,
  RemoveFilterChips,
  ClearFilter,
  setFilterCat,
} = Filter.actions;

export const selectFilterChips = (state) => state.Filter.filterChips;
export const selectFilter = (state) => state.Filter.filter;
export const selectPrice = (state) => state.Filter.filter.price;
export const selectPriceRange = (state) => state.Filter.filter.price.range;
export const selectBrands = (state) => state.Filter.filter.brands;
export const selectcatagory = (state) => state.Filter.filter.catagory;

export default Filter.reducer;
