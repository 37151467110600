import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import "./Return.css";

import { IoMdArrowRoundBack } from "react-icons/io";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Divider from "@mui/material/Divider";
import { getOrders, getSingleOrder } from "../../Api/Order";
import { selectCurrency } from "../../Features/Currency/Currency";
import { useRef } from "react";
import success from "../../Assets/success.png";
import { useParams, useNavigate } from "react-router-dom";
//import {formatDateTime} from '../../Lib/Lib';
import NoImage from "../../Assets/no_image.jpg";
import { IMAGE_URL_BASE_PATH } from "../../App/GlobalEnv";
import Avatar from "@mui/material/Avatar";
import { ReturnListLoader, SingleLine } from "../../Components/Loader/Loader";
import { useReturnMutate } from "../../Features/Return/ReturnMutate";
import { showToast } from "../../Components/Toast/ShowToast";
import { getReturn } from "../../Api/Return";
import AccountGoback from "../Account/AccountGoback";
export default function Return({ orderID, isCheckoutpage }) {
  const { paramOrderid } = useParams();
  let Navigate = useNavigate();
  const returnMut = useReturnMutate();
  const ReturnValue = useRef([]);

  const isButton = ReturnValue.current.some((item) => item == false);
  console.log("hjhmhmhjn", isButton);

  const finalid = paramOrderid ? paramOrderid : orderID;

  const {
    isPending,
    isError,
    data: OrderData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["orderSummery", finalid],
    queryFn: async () => {
      const result = await getSingleOrder(finalid);
      console.log("result.orders", result.orders);
      return result.orders[0];
    },
    staleTime: 0,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });

  const {
    isPending: isPendingd,
    isError: isErrord,
    data: returnData,
  } = useQuery({
    queryKey: ["return", OrderData?.Ecom_CO_Order_NO],
    queryFn: async () => {
      const orders = await getReturn(OrderData?.Ecom_CO_Order_NO);
      return orders;
    },
    staleTime: 0,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
    enabled: !!OrderData?.Ecom_CO_Order_NO,
  });

  const [productHolder, setproductHolder] = useState(false);

  const getDataById = (ID) => {
    return returnData?.find((item) => item?.Ecom_OR_LI_id == ID);
  };

  const HandleCheck = (e, Item) => {
    // console.log(e.target.checked);

    setproductHolder(e.target.checked);
    setValue(`return.${Item.Ecom_Co_LI_id}.qty`, Item.Ecom_Co_LI_quantity);
    setValue(`return.${Item.Ecom_Co_LI_id}.product`, Item.Product);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    setError,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues: {}; you can populate the fields by this attribute
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "return",
  });

  useEffect(() => {
    // alert(JSON.stringify(getDataById(74)));
    // OrderData?.LineItems?.forEach(element => {
    //   console.log('element element',element.Ecom_Co_LI_quantity);
    //   setValue(`return.${element.LI_Ecom_CO_Id}.qty`,element.Ecom_Co_LI_quantity);
    // });
    // alert(JSON.stringify(returnData));
  }, [returnData]);

  const handleQtyChange = (id, delta) => {
    // const currentQty = getValue(`return.${Item.Ecom_Co_LI_id}.qty`);
    // const newQty = currentQty + delta;

    // if (newQty >= 1 && newQty <= Item.Ecom_Co_LI_quantity) {
    //   setValue(`return.${Item.Ecom_Co_LI_id}.qty`, newQty);
    // }

    const currentQty = getValues(`return.${id}.qty`);
    const newQty = parseInt(currentQty) + delta;

    setValue(`return.${id}.qty`, newQty);
  };

  const handleReturn = (data) => {
    console.log("Return form data:", data);

    const returnPayload = {
      ecom_Cust_id: OrderData?.Ecom_CO_Cust_id,
      ecom_Order_Number: OrderData?.Ecom_CO_Order_NO,
      request_by: "Customer",
      products: [],
    };

    data?.return?.forEach((element) => {
      if (element?.returncheck) {
        returnPayload.reason = element?.returnreason;
        returnPayload.products.push({
          productID: element.product.IM_ID,
          quantity: element?.qty,
          reason: element?.returnreason,
        });
      }
      console.log("Return form data:", element);
    });

    if (returnPayload["products"]?.length <= 0) {
      showToast("Choose at least 1 Item", "error");
      return;
    }
    console.log("Return payload:", returnPayload);

    returnMut.mutate(returnPayload);
    Navigate("/account");
  };

  // console.log("returnwatch", watch());
  // console.log("returnwatch",'errors' ,errors);

  // if(!isLoading){
  //   return (
  //     <div className="container">
  //       <ReturnListLoader/>
  //     </div>

  //   )
  // }
  return (
    <>
      <form onSubmit={handleSubmit(handleReturn)}>
        <div className="container-d">
          <div className="d-flex">
            <AccountGoback />
          </div>

          <section className="mx-5 my-3">
            <h1>Choose Items to return</h1>
            {!isLoading ? (
              <div>
                Ordered on {OrderData?.Ecom_CO_createdat} Order#{" "}
                {OrderData?.Ecom_CO_Order_NO}
              </div>
            ) : (
              <SingleLine />
            )}
          </section>
          {!isLoading ? (
            <>
              <section>
                <div className="row">
                  <div className="col-sm-11">
                    {OrderData?.LineItems?.map((Item) => {
                      let subid = getDataById(Item.Ecom_Co_LI_id);
                      ReturnValue.current.push(Boolean(subid));
                      return (
                        <>
                          {/* {JSON.stringify(Boolean(subid))} */}
                          <div className="row m-2">
                            <div className="col-6">
                              {subid ? (
                                ""
                              ) : (
                                <input
                                  type="checkbox"
                                  {...register(
                                    `return.${Item.Ecom_Co_LI_id}.returncheck`
                                  )}
                                  onClick={(e) => HandleCheck(e, Item)}
                                />
                              )}

                              <div className="d-flex flex-row gap-2">
                                <Avatar
                                  variant="square"
                                  sx={{ width: 100, height: 100 }}
                                  src={
                                    typeof Item?.Product?.IM_PicturePath ==
                                    "string"
                                      ? IMAGE_URL_BASE_PATH +
                                        Item?.Product?.IM_PicturePath
                                      : // ? product.IM_PicturePath
                                        NoImage
                                  }
                                >
                                  N
                                </Avatar>
                                <div className="d-flex flex-column gap-2">
                                  <p>{Item?.Product?.IM_ItemName}</p>
                                  <p style={{ fontSize: "12px" }}>
                                    {Item?.Ecom_CO_currency}{" "}
                                    {Item.Ecom_Co_LI_price} * qty{" "}
                                    {Item.Ecom_Co_LI_quantity}
                                  </p>
                                  <p style={{ fontSize: "12px" }}>
                                    {Item?.Ecom_CO_currency}{" "}
                                    {Item.Ecom_Co_LI_price}
                                  </p>

                                  {subid ? (
                                    <>
                                      <div>
                                        <p className="text-danger text-danger-bold">
                                          Return Reason : {subid.Ecom_OR_Reason}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-1">
                         <input type="number" /> 
                     </div> */}

                            <div className="col-6 row justify-content-start align-items-center gap-2">
                              {getValues(
                                `return.${Item.Ecom_Co_LI_id}.returncheck`,
                                {
                                  required: "error message",
                                }
                              ) && (
                                <div className="d-flex flex-column gap-2">
                                  <div className="col-12">
                                    <label>Why are You Returning This?</label>
                                    <select
                                      {...register(
                                        `return.${Item.Ecom_Co_LI_id}.returnreason`,
                                        {
                                          required: "Choose a reasone",
                                        }
                                      )}
                                    >
                                      <option value={""}>
                                        Choose a response
                                      </option>
                                      <option value={"bought-by-mistake"}>
                                        Bought By Mistake
                                      </option>
                                      <option value={"wrong-items-sent"}>
                                        Wrong Items Were Sent
                                      </option>
                                      <option value={"damaged-product-and-box"}>
                                        Product and Shipping Box Both Damaged
                                      </option>
                                      <option value={"no-longer-needed"}>
                                        No Longer Needed
                                      </option>
                                      <option value={"better-price-available"}>
                                        Better Price Available
                                      </option>
                                      <option value={"missing-or-broken-parts"}>
                                        Missing or Broken Parts
                                      </option>
                                      <option value={"late-arrival"}>
                                        Item Arrived Too Late
                                      </option>
                                      <option value={"damaged-product-only"}>
                                        Product Damaged but Shipping Box Intact
                                      </option>
                                      <option value={"defective-item"}>
                                        Item Defective or Doesn't Work
                                      </option>
                                      <option value={"extra-item-received"}>
                                        Received Extra Item Not Purchased
                                      </option>
                                      <option value={"inaccurate-description"}>
                                        Inaccurate Website Description
                                      </option>
                                      <option value={"unauthorized-purchase"}>
                                        Didn't Authorize Purchase
                                      </option>
                                    </select>
                                    <div className="is-error-text">
                                      {" "}
                                      {
                                        errors?.return?.[Item.Ecom_Co_LI_id]
                                          ?.returnreason?.message
                                      }{" "}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    {/* <div
                              className="ShoppingBag_Quantity"
                              data-label="Quantity"
                            >
                              <input type="number" />
                              <div className="ShoppingBagTableQuantity">
                                <button
                                  onClick={() =>HandleQtyChange()}
                                >-</button>
                                <input type="text"
                                         onClick={() =>HandleQtyChange()}
                                min="1" max={`${Item.Ecom_Co_LI_quantity}`} 
                                {...register(`return.${Item.Ecom_Co_LI_id}.qty`)}
                                 />
                                <button  onClick={() =>HandleQtyChange()}>+</button>
                              </div>
                            </div> */}
                                    <div className=" d-flex flex-column">
                                      <div className="ShoppingBagTableQuantity">
                                        <button
                                          onClick={() =>
                                            handleQtyChange(
                                              Item.Ecom_Co_LI_id,
                                              -1
                                            )
                                          }
                                          disabled={
                                            getValues(
                                              `return.${Item.Ecom_Co_LI_id}.qty`
                                            ) <= 1
                                          }
                                        >
                                          -
                                        </button>

                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            handleQtyChange(
                                              Item.Ecom_Co_LI_id,
                                              e.target.value
                                            )
                                          }
                                          {...register(
                                            `return.${Item.Ecom_Co_LI_id}.qty`,
                                            {
                                              min: {
                                                value: 1,
                                                message:
                                                  "Minimum quantity is 1",
                                              },
                                              max: {
                                                value: Item.Ecom_Co_LI_quantity,
                                                message: `Quantity cannot exceed ${Item.Ecom_Co_LI_quantity}`,
                                              },
                                              required: {
                                                message: "Quantity is required",
                                              },
                                            }
                                          )}
                                        />
                                        <button
                                          onClick={() =>
                                            handleQtyChange(
                                              Item.Ecom_Co_LI_id,
                                              +1
                                            )
                                          }
                                          disabled={
                                            getValues(
                                              `return.${Item.Ecom_Co_LI_id}.qty`
                                            ) >= Item.Ecom_Co_LI_quantity
                                          }
                                        >
                                          +
                                        </button>
                                      </div>

                                      <div className="is-error-text">
                                        {" "}
                                        {
                                          errors?.return?.[Item.Ecom_Co_LI_id]
                                            ?.qty?.message
                                        }{" "}
                                      </div>
                                      {/* <p className="error"> {JSON.stringify(errors?.return)} </p> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <Divider />
                        </>
                        // <ProductReturn
                        //   Item={items}
                        //   onChange={(da) => console.log(da)}
                        // />
                      );
                    })}
                  </div>
                  {isButton && (
                    <Grid sx={{ padding: 3, width: "fit-content" }}>
                      <button className="order_button_track">Continue</button>
                    </Grid>
                  )}
                </div>
              </section>
            </>
          ) : (
            <ReturnListLoader />
          )}
        </div>
      </form>
    </>
  );
}
