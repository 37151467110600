const ActivitieLoader = ({loaderText,isloader}) => {
  return (
    <div className={isloader?'loader-wrapper':'loader-wrapper d-none'}>
      <div className='loader'>
        <div className='loader-inner'></div>
      </div>
      <p className='loader-text'> {loaderText}, please wait...</p>
    </div>
  )
}

export default ActivitieLoader
