export const BASE_URL='http://202.21.38.173:9096/api/v1';
export const openstreetmap='https://nominatim.openstreetmap.org/search.php?&countrycodes=my&format=jsonv2';

export const END_POINTS={
    'CATEGORY':BASE_URL+'/ProductsAPI/GetCategory',
    'MENU':BASE_URL+'/ProductsAPI/GetMenu',
    'PRODUCTS':BASE_URL+'/ProductsAPI/GetProducts',
    // 'LOGIN':BASE_URL+'/UserAPI/MobileLogin',
    'ALL':BASE_URL+'/ProductsAPI/FetchAll',
    'LOGIN':BASE_URL+'/auth/Login',
    'LOGOUT':BASE_URL+'/auth/LogOut',
    'USER_REGISTER':BASE_URL+'/auth/Register',
    'USER_CONFIRM_EMAIL':BASE_URL+'/auth/ConfirmEmail',
    'USER_FORGOT':BASE_URL+'/auth/ForgotPassword',
    'USER_REST':BASE_URL+'/auth/ResetPassword',
    'USER_CART':BASE_URL+'/CartAPI/AddOrUpdate',
    'CART_GET':BASE_URL+'/CartAPI/GetCart',
    'CART_REMOVE':BASE_URL+'/CartAPI/Remove',
    'GUEST_TOKEN':BASE_URL+'/auth/GuestToken',
    'SINGLE_PRODUCT':BASE_URL+'/ProductsAPI/GetIndividualProduct',
    'CHECK_OUT':BASE_URL+'/CheckoutAPI/AddOrUpdate',
    'GET_SINGLE_ORDERS':BASE_URL+'/CheckoutAPI/GetOrders',   
    'ORDERS_ALL':BASE_URL+'/CheckoutAPI/GetOrder'   ,
    'ADDRESS':BASE_URL+'/shipmentAPI/AddOrUpdate',
    'CUSTOMER_ADDRESS':BASE_URL+'/ShipmentAPI/GetShipmentDetail',
    'REMOVE_ADDRESS':BASE_URL+'/ShipmentAPI/Remove',
    'ADD_WISHLIST':BASE_URL+'/WishlistAPI/Add',
    'CUSTOMER_WISHLIST':BASE_URL+'/WishlistAPI/GetWishlist',
    'REMOVE_WISHLIST':BASE_URL+'/WishlistAPI/Remove',
    'CANCEL_ORDER':BASE_URL+'/CheckoutAPI/CancelOrder',
    'PAYMENT':BASE_URL+'/Payment/create-checkout-session',
    'ORDER_STATUS':BASE_URL+'/CheckoutAPI/GetOrderStatus',
    'RETURN':BASE_URL+'/CheckoutAPI/Return',
    'RETURN_LIST':BASE_URL+'/CheckoutAPI/GetReturnOrders'
};