import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./AccountManager.css";
import { useScrollToTop } from "../../hooks/useScrollToTop";
export default function AccountGoback({ data, path, justifycontent }) {
  const navigate = useNavigate();
  const [scrollToTop ] = useScrollToTop();
  const handleGoBack = () => {
    navigate(-1);
    scrollToTop();
  };
  return (
    <div className="row account_go_back">
      {/* Icon positioned in the left corner */}
      <div onClick={handleGoBack} className="col-6" style={{cursor:'pointer'}}>
        <IoMdArrowRoundBack size={30} />
      </div>
      {/* Text centered */}
      <div
        className=" col-6 account_header"
        //  style={{ fontSize: 25, fontWeight: 600 }}
      >
        {data}
      </div>
    </div>
  );
}
