// userRegistrationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registrationStatus: 'idle',
  registrationError: null,
  registeredUser: null,
  Username: '',
  Password: '',
  ConfirmPassword: '',
  Phonenumber: '',
  Email: ''
};

const userRegistrationSlice = createSlice({
  name: 'userRegistration',
  initialState,
  reducers: {
    storeRegisterData(state, action){

        const {Username, Email, Phonenumber, Password, ConfirmPassword} = action.payload;

        state.registrationStatus = 'idle';
        state.registrationError = null;
        state.registeredUser = null;
        state.Username =Username;
        state.Password = Password;
        state.ConfirmPassword = ConfirmPassword;
        state.Phonenumber =Phonenumber;
        state.Email =Email;
    },

    setMobile(state, action) {
      state.mobile = action.payload;
    },
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    registerUserPending(state) {
      state.registrationStatus = 'pending';
      state.registrationError = null;
    },
    registerUserSuccess(state, action) {
      state.registrationStatus = 'success';
      state.registeredUser = action.payload;
    },
    registerUserError(state, action) {
      state.registrationStatus = 'error';
      state.registrationError = action.payload;
    },
    resetRegistrationState(state) {
      state.registrationStatus = 'idle';
      state.registrationError = null;
      state.registeredUser = null;
      state.Username = '';
      state.Password = '';
      state.ConfirmPassword = '';
      state.mobile = '';
      state.Email = '';
    },
  },
});

export const {
  setUsername,
  setPassword,
  setConfirmPassword,
  setMobile,
  setEmail,
  setFirstName,
  setLastName,
  registerUserPending,
  registerUserSuccess,
  registerUserError,
  resetRegistrationState,
  storeRegisterData
} = userRegistrationSlice.actions;

export default userRegistrationSlice.reducer;