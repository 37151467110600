
const isValidJSON = str => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

const isUser=()=>{
    try{
        let isactive=JSON.parse(localStorage.getItem("hyuser")) || false;
        if(isactive){
            return true;
        }else{
            return false;
        }
    }catch(err){
        console.log('ERROR',err);
        return false;
    }
}


const userData = () => {
    try{
        return JSON.parse(localStorage.getItem("hyuser")) || false;
    }catch(err){
        return false;
    }
   
}
const userDataGet = (param) => {
    try{
        return JSON.parse(localStorage.getItem("hyuser"))[param] || null;
    }catch(err){
        return false;
    }
    
}

const LocalGet = (param) => {
    console.log(param);
    let localData=localStorage.getItem(param);
    if(isValidJSON(localData)){
        return JSON.parse(localData) || null;
    }
    else{
        return localData || null;
    }
    
}

const LocalSet = (key, value) => {
    localStorage.setItem(key, value);
}

const LocalUpdate = (key, value) => {
    const existingData = JSON.parse(localStorage.getItem("hyuser")) || {};
    if (existingData[key] !== undefined) {
        existingData[key] = value;
        localStorage.setItem("hyuser", JSON.stringify(existingData));
    } else {
        console.error(`Key ${key} does not exist.`);
    }
}

const LocalDelete = (key) => {
    const existingData = JSON.parse(localStorage.getItem("hyuser")) || {};
    if (existingData[key] !== undefined) {
        delete existingData[key];
        localStorage.setItem("hyuser", JSON.stringify(existingData));
    } else {
        console.error(`Key ${key} does not exist.`);
    }
}

export {
    userData,
    LocalGet,
    LocalSet,
    LocalUpdate,
    LocalDelete,
    userDataGet,
    isUser
}