import axios from "axios";
import {LocalGet,LocalSet} from '../Local'
import {getGuestToken} from '../GustUser';

  const api=axios.create();

  api.interceptors.request.use(async (config) => {
      let token =LocalGet('guestToken');
  
      if(!token){
        token=await getGuestToken();
        LocalSet('guestToken',token);
      }
  
      config.headers['Authorization']=`Bearer ${token}`;
      return config;
  
  });

  export {api};