
import './verification.css'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OtpInput from 'react-otp-input';
import { CgSmartphone } from "react-icons/cg";

import { useState } from 'react'

const VerificationOtp = () => {


    const [otp, setOtp] = useState('');

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    return (
        <>

            <div class="d-flex justify-content-center align-items-center " >

                <Card className="d-flex p-2" >
                    <Box className="d-flex p-2" >
                        <CardContent sx={{ flex: '1 0 auto' }} className="m-2 d-flex flex-column justify-content-center align-items-center">
                  
                            <div className='loginSignUpTabsContentRegister m-2 text-center'>
                            <CgSmartphone size={80}/>

                            <p className='text-center'> Verification </p>
                            <p className='text-center'>You will get OTP via whatup and Email</p>
                            </div>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input className='p-2'
                                    {...props} />}
                            />

                        </CardContent>

                    </Box>

                </Card>
            </div>

        </>
    )
}

export default VerificationOtp;