import { END_POINTS } from "../Api/Config/EndPoint";
import {api} from './Config/AxioConfig';
import { LocalGet, userData } from "./Local";
import { useMutation } from "@tanstack/react-query";
import { userDataGet } from "./Local";
// const AddAddress = async (data) => {
//   let payload = {

//     shipment_Customer_Id: LocalGet('user_id'),
//     shipment_AddressLine1: data.address1,
//     shipment_AddressLine2: data.address2,
//     shipment_Landmark: data.landmark,
//     shipment_Country: data.country,
//     shipment_State: data.state,
//     shipment_City: data.city,
//     shipment_Pincode: data.pinCode,
//     shipment_Address_Name: "abishek",
//     shipment_Notes: "thank you for the API",
//     shipment_default_address: true,
//     shipment_Receiver_No: "89658966",
//     shipment_Status: true,

//     shipment_CreatedBy: "string",

//     shipment_UpdatedBy: "string",
//   };
//   try {
//     const response = await axios.post(END_POINTS.ADDRESS, { params: payload });
//     console.log("addressData",response);
//     return response.data;
//   } catch (error) {
//     console.error("ERROR - GROUP", error);
//   }
// };
// const addAddressData= async(address)=>{
//   return axios.post(END_POINTS.ADDRESS,address)

// }

const addAddressData = async (address) => {
  console.log("fjvbbvj", address);
  try {
    const response = await api.post(END_POINTS.ADDRESS, address, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("fbhjbvh", response.data);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    throw error;
  }
};
const fetchAddressData = async () => {
  let payload = {
    customerId: userDataGet("user_id"),
  };
  try {
    const response = await api.get(
      END_POINTS.CUSTOMER_ADDRESS,
      { params: payload },
      {
        header: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};
const RemoveAddress = async (shipment_id) => {
  let payload = {
    shipment_id: shipment_id,
  };
  try {
    const response = await api.post(END_POINTS.REMOVE_ADDRESS, payload, {
      header: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};
const SetDefaultAddress = async (payload) => {
  payload.Shipment_default_address = true;

  try {
    const response = await api.post(END_POINTS.ADDRESS, payload, {
      header: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const EditedAddress = async (payload) => {
  console.log("ihdsh", payload.Shipment_Id);
  const Shipment_State = payload?.shipment_State?.name || "Default State";
  const Shipment_City = payload?.shipment_City?.name || "Default State";

  let loadedData = {
    Shipment_Pincode: payload.shipment_Pincode,
    Shipment_AddressLine1: payload.shipment_AddressLine1,
    Shipment_AddressLine2: payload.shipment_AddressLine2,
    Shipment_Landmark: payload.shipment_Landmark,
    Shipment_Country: payload.shipment_Country,
    Shipment_State: Shipment_State,
    Shipment_City: Shipment_City,
    Shipment_Receiver_Name: payload.shipment_Receiver_Name,
    Shipment_Receiver_No: payload.shipment_Receiver_No,
    Shipment_Notes: payload.shipment_Notes,
    Shipment_Address_Name: payload.shipment_Address_Name,
    Shipment_default_address: payload.shipment_default_address,
    Shipment_Id: payload.Shipment_Id,
    Shipment_CreatedBy: "admin",
    Shipment_UpdatedBy: "admin",
    Shipment_Customer_Id: payload.shipment_Customer_Id,
  };

  console.log("loadedData", loadedData);

  // Safely access Shipment_State and set a default value if it's undefined

  //  payload.state =Shipment_State
  //  payload.city=Shipment_City
  // console.log("bvsdjvb", payload);

  try {
    const response = await api.post(END_POINTS.ADDRESS, loadedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw error; // Ensure the error is propagated for onError to catch it
  }
};

export {
  addAddressData,
  fetchAddressData,
  RemoveAddress,
  SetDefaultAddress,
  EditedAddress,
};
