// import {
//     PayPalScriptProvider,
//     PayPalCardFieldsProvider,
//     PayPalNameField,
//     PayPalNumberField,
//     PayPalExpiryField,
//     PayPalCVVField,
//     usePayPalCardFields,
// } from "@paypal/react-paypal-js";

// const SubmitPayment = () => {
//     const { cardFields, fields } = usePayPalCardFields();

//     function submitHandler() {
//         if (typeof cardFields.submit !== "function") return; // validate that `submit()` exists before using it

//         cardFields
//             .submit()
//             .then(() => {
//                 // submit successful
//             })
//             .catch(() => {
//                 // submission error
//             });
//     }
//     return <button onClick={submitHandler}>Pay</button>;
// };

// // Example using individual card fields
// export default function Paypal() {
//     function createOrder() {
//         // merchant code
//     }
//     function onApprove() {
//         // merchant code
//     }
//     function onError() {
//         // merchant code
//     }
//     return (
//         <PayPalScriptProvider
//             options={{
//                 clientId: "your-client-id",
//                 components: "card-fields",
//             }}
//         >
//             <PayPalCardFieldsProvider
//                 createOrder={createOrder}
//                 onApprove={onApprove}
//                 onError={onError}
//             >
//                 <PayPalNameField />
//                 <PayPalNumberField />
//                 <PayPalExpiryField />
//                 <PayPalCVVField />

//                 <SubmitPayment />
//             </PayPalCardFieldsProvider>
//         </PayPalScriptProvider>
//     );
// }


import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch('/create-intent', {
      method: 'POST',
    });

    const {client_secret: clientSecret} = await res.json();

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

export default function Paypal() {
    return (
  <Elements stripe={stripePromise} options={options}>
    <CheckoutForm />
  </Elements>
);
}


