import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  showToast,
  showToastBottomCenter,
} from "../../Components/Toast/ShowToast";
import { AddandupdateCart, RemoveCartDB, UpdateCart } from "../../Api/Cart";

const persistedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];


const initialState = {
  items: persistedCartItems,
  totalAmount: persistedCartItems.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.CDtl_Price * currentValue.CDtl_Quantity,
    0
  ),
};

export const addToCartAsync = createAsyncThunk(
  "cart/addToCart",
  async (item, { getState, dispatch, rejectWithValue }) => {
    const { token } = getState().Auth;

    try {
      if (token) {
        let tempitem = item;

        delete tempitem["Product"];
        const data = await AddandupdateCart(tempitem);

        dispatch(addToCartLocal(item));
        console.log("AddandupdateCart", data);
      } else {
        dispatch(addToCartLocal(item));
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeCartAsync = createAsyncThunk(
  "cart/removeCartAsync",
  async (data, { getState, dispatch, rejectWithValue }) => {
    const { productID, cartId } = data;
    const { token } = getState().Auth;

    try {
      if (token) {
        const DataHolder = await RemoveCartDB(cartId, productID);
        dispatch(RemoveCart(data));

      } else {
        dispatch(RemoveCart(data));
      }
    } catch (error) {
      console.error("AddandupdateCart", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const updateCartAsync = createAsyncThunk(
  "cart/updateCartAsync",
  async (data, { getState, dispatch, rejectWithValue }) => {
    const { productID, quantity, price } = data;
    const { token } = getState().Auth;

    try {
      if (token) {
        const dataHolder = await UpdateCart(productID, quantity, price);
        dispatch(localUpdateQuantity(data));

        console.log("localUpdateQuantity", data);
      } else {
        dispatch(localUpdateQuantity(data));
      }
    } catch (error) {
      console.error("localUpdateQuantity", error.message);
      return rejectWithValue(error.message);
    }
  }
);

const MAX_QUANTITY = 20;

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems(state, action) {
      console.log("cart set items", action.payload);
      state.items = action.payload;
    },
    ClearCart(state, action) {
      state.items = [];
      localStorage.setItem("cartItems", JSON.stringify([]));
    },
    SyncLocalToState(state, action) {
      console.log(
        "cart Sync Local",
        JSON.parse(localStorage.getItem("cartItems"))
      );
      state.items = JSON.parse(localStorage.getItem("cartItems")) || [];
    },
    setTotalAmount(state, action) {
      state.totalAmount = action.payload;
    },
    addToCartLocal(state, action) {
      const product = action.payload;

      const existingItem = state.items.find(
        (item) => item.CDtl_Product_Id === product.CDtl_Product_Id
      );

      if (existingItem) {
        if (existingItem.CDtl_Quantity < MAX_QUANTITY) {
          existingItem.CDtl_Quantity += 1;
          console.log(state.totalAmount, "state.totalAmountstate.totalAmount");
          state.totalAmount += product.CDtl_Price;
        }
      } else {
        state.items.push({ ...product, CDtl_Quantity: 1 });
        console.log(state.totalAmount, "state.totalAmountstate.totalAmount");
        state.totalAmount += product.CDtl_Price;
      }

      const updatedCartItems = JSON.stringify(state.items);
      localStorage.setItem("cartItems", updatedCartItems);
    },
    // addToCart(state, action) {
    //   const product = action.payload;
    //   const existingItem = state.items.find(
    //     (item) => item.CDtl_Product_Id === product.CDtl_Product_Id
    //   );

    //   if (existingItem) {
    //     if (existingItem.CDtl_Quantity < MAX_QUANTITY) {
    //       existingItem.CDtl_Quantity += 1;
    //       console.log(state.totalAmount, "state.totalAmountstate.totalAmount");
    //       state.totalAmount += product.CDtl_Price;
    //     }
    //   } else {
    //     state.items.push({ ...product, CDtl_Quantity: 1 });
    //     console.log(state.totalAmount, "state.totalAmountstate.totalAmount");
    //     state.totalAmount += product.CDtl_Price;
    //   }
    //   const updatedCartItems = JSON.stringify(state.items);
    //   localStorage.setItem("cartItems", updatedCartItems);

    //   const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;

    //   if (persistedUser) {
    //     (async () => {
    //       try {
    //         // const cartData = await ProcessCartItems(JSON.parse(updatedCartItems));
    //         // const cartData = await TransformLocalCartToApiCart(JSON.parse(updatedCartItems));
    //         // console.log('AddandupdateCart',JSON.parse(updatedCartItems.forEach(obj => delete obj.Product)));
    //         console.log(
    //           "obj.Product",
    //           JSON.parse(updatedCartItems).forEach((obj) => console.log(obj))
    //         );
    //         // delete product.Product;
    //         delete product["Product"];
    //         // console.log('obj.Product',product);

    //         const data = await AddandupdateCart(product);

    //         if (data["message"]) {
    //           showToast(data["message"], "success");
    //         } else {
    //           showToast("Failed To add Cart", "error");
    //         }
    //       } catch (error) {
    //         console.error(error);
    //         showToast("Something went wrong", "error");
    //       }
    //     })();
    //   } else {
    //     showToast("Added To Cart", "success");
    //   }

    //   state.totalAmount = state.items.reduce(
    //     (accumulator, currentValue) =>
    //       accumulator + currentValue.CDtl_Price * currentValue.CDtl_Quantity,
    //     0
    //   );
    // },
    updateQuantity(state, action) {
      const { productID, quantity, price } = action.payload;
      console.log("updatecart", price);
      console.log("updatecart", state.items);
      const itemToUpdate = state.items.find(
        (item) => item.CDtl_Product_Id === productID
      );
      if (itemToUpdate) {
        console.log("updatecart", "IN");
        // const difference = quantity - itemToUpdate.quantity;
        // if (quantity <= MAX_QUANTITY) {
        //   itemToUpdate.quantity = quantity;
        // state.totalAmount += difference * itemToUpdate.productPrice;
        //   state.totalAmount = itemToUpdate.quantity * itemToUpdate.productPrice;
        // } else {
        //   itemToUpdate.quantity = MAX_QUANTITY;
        //   state.totalAmount +=
        //     (MAX_QUANTITY - itemToUpdate.quantity) * itemToUpdate.productPrice;
        // }

        if (quantity <= MAX_QUANTITY) {
          itemToUpdate.CDtl_Quantity = quantity;
          // state.totalAmount += difference * itemToUpdate.productPrice;
          state.totalAmount =
            itemToUpdate.CDtl_Quantity * itemToUpdate.CDtl_Price;
        } else {
          itemToUpdate.CDtl_Quantity = MAX_QUANTITY;
          state.totalAmount +=
            (MAX_QUANTITY - itemToUpdate.CDtl_Quantity) *
            itemToUpdate.CDtl_Price;
        }

        try {
          const itemStringify = JSON.stringify(state.items);
          const persistedUser =
            JSON.parse(localStorage.getItem("hyuser")) || null;

          if (persistedUser) {
            (async () => {
              try {
                const item = JSON.parse(itemStringify);
                // const cartData = await ProcessCartItems(item);
                // const cartData = await TransformLocalCartToApiCart(JSON.parse(item));
                // const data = await AddandupdateCart(cartData);
                const data = await UpdateCart(productID, quantity, price);

                if (data.message) {
                  showToast(data.message, "success");
                } else {
                  showToast("Failed To add Cart", "error");
                }
              } catch (error) {
                console.error(error);
              }
            })();
          } else {
            showToast("Added To Cart", "success");
          }
        } catch (e) {
          console.log(e);
          showToast("Something went wrong", "error");
        }
        let total = state.items.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseInt(currentValue.CDtl_Price) *
              parseFloat(currentValue.CDtl_Quantity),
          0
        );
        state.totalAmount = total;
        // Save to localStorage
        console.log("cartItem", state.items);
        localStorage.setItem("cartItems", JSON.stringify(state.items));
      }
    },
    localUpdateQuantity(state, action) {
      const { productID, quantity, price } = action.payload;
      console.log("updatecart", action.payload);
      console.log("updatecart", price);
      console.log("updatecart", state.items);
      const itemToUpdate = state.items.find(
        (item) => item.CDtl_Product_Id === productID
      );

      if (itemToUpdate) {
        if (quantity <= MAX_QUANTITY) {
          itemToUpdate.CDtl_Quantity = quantity;
          // state.totalAmount += difference * itemToUpdate.productPrice;
          state.totalAmount =
            itemToUpdate.CDtl_Quantity * itemToUpdate.CDtl_Price;
        } else {
          itemToUpdate.CDtl_Quantity = MAX_QUANTITY;
          state.totalAmount +=
            (MAX_QUANTITY - itemToUpdate.CDtl_Quantity) *
            itemToUpdate.CDtl_Price;
        }

        let total = state.items.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseInt(currentValue.CDtl_Price) *
              parseFloat(currentValue.CDtl_Quantity),
          0
        );

        state.totalAmount = total;
        // Save to localStorage
        console.log("cartItem", state.items);
        localStorage.setItem("cartItems", JSON.stringify(state.items));
      }
    },
    // removeFromCart(state, action) {

    //   const DataHolder = action.payload;
    //   var productId=DataHolder.productID;
    //   var CartId=DataHolder.cartId;
    //   console.log('--------removeFromCart---------------');
    //   console.log('removeFromCart',DataHolder);

    //   const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;

    //   if (persistedUser) {
    //     (async () => {
    //       try {

    //         // const item = JSON.parse(itemStringify);
    //         // const cartData = await ProcessCartItems(item);
    //         // const cartData = await TransformLocalCartToApiCart(JSON.parse(item));
    //         // const data = await AddandupdateCart(cartData);
    //         // console.log('removeFromCart',CartId,productId);

    //         const data = await RemoveCartDB(CartId,productId);

    //         if (data.message) {
    //           showToast(data.message, 'success');
    //         } else {
    //           showToast("Failed To Remove", 'success');
    //         }
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     })();

    //   }

    //   const itemToRemove = state.items.find(
    //     (item) => item.CDtl_Product_Id === productId
    //   );
    //   console.log('persistedUser',persistedUser);
    //   if (itemToRemove) {

    //     state.totalAmount -= itemToRemove.productPrice * itemToRemove.CDtl_Quantity;
    //     state.items = state.items.filter(
    //       (item) => item.CDtl_Product_Id !== productId
    //     );

    //     showToast("Item Removed", 'success');
    //     // Save to localStorage
    //     localStorage.setItem("cartItems", JSON.stringify(state.items));
    //   }
    // },
    RemoveCart(state, action) {
      const { productID, cartId } = action.payload;

      const itemToRemove = state.items.find(
        (item) => item.CDtl_Product_Id === productID
      );

      if (itemToRemove) {
        // state.totalAmount -= itemToRemove.productPrice * itemToRemove.CDtl_Quantity;

        let total = state.items.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseInt(currentValue.CDtl_Price) *
              parseFloat(currentValue.CDtl_Quantity),
          0
        );
        state.totalAmount = total;

        state.items = state.items.filter(
          (item) => item.CDtl_Product_Id !== productID
        );
        showToast("Item Removed", "success");
        localStorage.setItem("cartItems", JSON.stringify(state.items));
      } else {
        console.log(
          "Noitemtoremove",
          itemToRemove,
          JSON.stringify(state.items)
        );
      }
    },

    removeFromCart(state, action) {
      const { productID, cartId } = action.payload;
      console.log("--------removeFromCart---------------");
      console.log("removeFromCart", action.payload);

      const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;

      if (persistedUser) {
        (async () => {
          try {
            const data = await RemoveCartDB(cartId, productID);
            if (data.message) {
              showToast(data.message, "success");
            } else {
              showToast("Failed To Remove", "success");
            }
          } catch (error) {
            console.error(error);
          }
        })();
      }

      const itemToRemove = state.items.find(
        (item) => item.CDtl_Product_Id === productID
      );
      console.log("persistedUser", persistedUser);

      if (itemToRemove) {
        // state.totalAmount -= itemToRemove.productPrice * itemToRemove.CDtl_Quantity;

        let total = state.items.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseInt(currentValue.CDtl_Price) *
              parseFloat(currentValue.CDtl_Quantity),
          0
        );
        state.totalAmount = total;

        state.items = state.items.filter(
          (item) => item.CDtl_Product_Id !== productID
        );
        showToast("Item Removed", "success");
        localStorage.setItem("cartItems", JSON.stringify(state.items));
      } else {
        console.log(
          "Noitemtoremove",
          itemToRemove,
          JSON.stringify(state.items)
        );
      }
    },
    syncCartToDB(state, action) {
      const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

      if (persistedUser || true) {
        (async () => {
          try {
            console.log("syncCartToDB", cartItems);

            // Merge local cart items with transformed API cart items
            const mergedCartItems = [...cartItems];

            console.log("syncCartToDB", mergedCartItems);

            // Remove duplicates
            const uniqueCartItems = mergedCartItems.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.CDtl_Product_Id === item.CDtl_Product_Id
                )
            );

            console.log("syncCartToDB", "uniqueCartItems", uniqueCartItems);

            const FinalCartEntry = uniqueCartItems.map((itm) => {
              delete itm["Product"];
              return itm;
            });

            console.log("syncCartToDB", "FinalCartEntry", FinalCartEntry);

            if (persistedUser) {
              (async () => {
                try {
                  const data = await AddandupdateCart(FinalCartEntry);

                  if (data["message"]) {
                    // state.items = [];
                    // localStorage.setItem("cartItems", JSON.stringify([]));
                    showToast(data["message"], "success");
                  } else {
                    showToast("Cart Sync Success", "error");
                  }
                } catch (error) {
                  console.error("syncCartToDB", error);
                  showToast("Cart Sync Something went wrong", "error");
                }
              })();
            } else {
              showToast("Added To Cart", "success");
            }

            // Update Redux state
            // dispatch({
            //   type: 'UPDATE_CART',
            //   payload: uniqueCartItems,
            // });
            // Update state
            // state.items=action.payload;

            // console.log("before",state.currentValue);
            // state.set('items',uniqueCartItems);

            // Update localStorage
            // localStorage.setItem("cartItems", JSON.stringify(FinalCartEntry));
          } catch (error) {
            console.error(error);
          }
        })();
      }
    },
    syncCartData(state, action) {
      const persistedUser = JSON.parse(localStorage.getItem("hyuser")) || null;
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

      const apiCartItems = action.payload;

      if (persistedUser) {
        (async () => {
          try {
            console.log("cart persistedUser", persistedUser);
            // const response = await getCartItems();
            // console.log('transformedApiCartItems',persistedresponseUser);
            // var apiCartItems = response.data;
            console.log("transformedApiCartItems", apiCartItems);

            // Transform API cart items to match local cart item format
            // const transformedApiCartItems =  TransformApiCartToLocalCart(apiCartItems);
            const transformedApiCartItems = apiCartItems.CartDtls;

            console.log(
              "cart transformedApiCartItems",
              transformedApiCartItems
            );

            // Merge local cart items with transformed API cart items
            const mergedCartItems = [...cartItems, ...transformedApiCartItems];

            console.log("cart mergedCartItems", mergedCartItems);

            console.log("cart start unique", mergedCartItems);

            // Remove duplicates
            const uniqueCartItems = mergedCartItems.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.CDtl_Product_Id === item.CDtl_Product_Id
                )
            );

            console.log(" cart uniqueCartItems", uniqueCartItems);

            // Update Redux state
            // dispatch({
            //   type: 'UPDATE_CART',
            //   payload: uniqueCartItems,
            // });
            // Update state
            // state.items=action.payload;

            // console.log("before",state.currentValue);
            // state.set('items',uniqueCartItems);

            // Update localStorage
            localStorage.setItem("cartItems", JSON.stringify(uniqueCartItems));
          } catch (error) {
            console.error(error);
          }
        })();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCartAsync.pending, (state) => {})
      .addCase(addToCartAsync.fulfilled, (state, action) => {
        showToast("Added To Cart", "success");
      })
      .addCase(addToCartAsync.rejected, (state, action) => {
        showToast("Failed To Add Cart", "error");
      })
      .addCase(removeCartAsync.pending, (state) => {})
      .addCase(removeCartAsync.fulfilled, (state, action) => {
        showToast("Item Removed From Cart", "success");
      })
      .addCase(removeCartAsync.rejected, (state, action) => {
        console.error(action);

        showToast("Failed To Removed From Cart", "error");
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        showToast("Added To Cart", "success");
      });
  },
});

export const {
  // addToCart,
  RemoveCart,
  removeFromCart,
  updateQuantity,
  setCartItems,
  setTotalAmount,
  syncCartData,
  localUpdateQuantity,
  ClearCart,
  SyncLocalToState,
  syncCartToDB,
  addToCartLocal,
} = cartSlice.actions;

export const selectCartItems = (state) => state.cart?.items;
export const selectCartTotalAmount = (state) => state.cart.totalAmount;

// // Async action to fetch cart details from API
// export const fetchCartDetails = (userId) => async (dispatch) => {
//   try {
//     // const response = await fetch('https://dummyjson.com/carts/add', {
//     //   method: 'POST',
//     //   headers: { 'Content-Type': 'application/json' },
//     //   body: JSON.stringify({ productId, quantity })
//     // });

//     await fetch('https://dummyjson.com/carts/add', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         userId: 1,
//         products: [
//           {
//             id: 144,
//             quantity: 4,
//           },
//           {
//             id: 98,
//             quantity: 1,
//           },
//         ]
//       })
//     })
//       .then(res => res.json())
//       .then((response) => {
//         console.log(response)
//       });

//     //   if (!response.ok) {
//     //     throw new Error('Failed to add item to cart');
//     //   }
//     //   const data = await response.json();
//     //   console.log(data)
//     //   // return data.cartItems; // Return updated cartItems from server
//     // } catch (error) {
//     //   // return thunkAPI.rejectWithValue(error.message);
//     // }

//     const response = await fetch(`https://dummyjson.com/carts/user/${userId}`);
//     console.log(response, "kfhjsd")
//     if (!response.ok) {
//       throw new Error("Failed to fetch cart details");
//     }
//     const cartData = await response.json();
//     console.log(cartData,"cartDatacartData");
//     // Dispatch actions to update cart items and total amount
//     dispatch(setCartItems(cartData.carts));
//     dispatch(setTotalAmount(cartData.total));
//   } catch (error) {
//     console.error("Error fetching cart details:", error);
//   }
// };

export default cartSlice.reducer;
