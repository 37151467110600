import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useDispatch } from "react-redux";
import { addToCart } from "../Features/Cart/cartSlice";
import { handleAddToCart, handleBuyNow } from "../Features/Cart/cartFn";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { FaStar } from "react-icons/fa";
import "../Components/Shared/ProductCardSample.css";
import { FiHeart } from "react-icons/fi";
import { PiShareNetworkLight } from "react-icons/pi";
import { UuiWon } from "../Lib/Lib";
import "../Components/Product/ProductMain/Product";
import { showLoader, hideLoader } from "../Features/Loader/Loader";
import { useParams } from "react-router-dom";
import { fetchProductsById } from "../Api/Product";
import NoImage from "../Assets/no_image.jpg";
import { useQuery } from "@tanstack/react-query";
import { ProductDetailsLoader } from "../Components/Loader/Loader";
import AdditionalInfo from "../Components/Product/AdditonInfo/AdditionalInfo";
import { CURRENCY_SYMBOLS } from "../Settings/config";
import { IMAGE_URL_BASE_PATH } from "../App/GlobalEnv";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useAddWhishlist, useRemoveWhishlist } from "../Features/Wishlist/Wish";
import { isEmpty } from "../Lib/Lib";
const ProductDetails = () => {
  const whishlistadd = useAddWhishlist();
  const whishlistRemove = useRemoveWhishlist();
  const { prid } = useParams();
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  //   const [ProductD,setProductD]=useState([]);

  const handleWishClick = () => {
    setClicked(!clicked);
  };

  const handleBuyNowd = (data) => {
    handleBuyNow(data).then(() => {
      navigate("/cart");
    });
  };

  const {
    isPending,
    isError,
    data: ProductD,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["tes", prid],
    queryFn: async () => {
      const result = await fetchProductsById(prid);
      return result;
    },
    staleTime: 0,
  });
  console.log("hbfb", ProductD?.IsInWishlist);
  console.log("outyu", ProductD?.TotalStock);
  const [wishList, setWishList] = useState(ProductD?.IsInWishlist);
  const [animateWishlist, setAnimateWishlist] = useState(false);
  if (  isLoading) {
    return (
      <div className="container">
        <ProductDetailsLoader />
      </div>
    );
  }
  const handleWishlistClick = (Productyyy) => {
    if (wishList) {
      // removewishlist(Productyyy,EcomID)

      whishlistRemove.mutate(Productyyy, prid);
      setWishList((wishList) => !wishList);
      setAnimateWishlist(true);
      // Reset the animation after it completes (0.3s)
      setTimeout(() => {
        setAnimateWishlist(false);
      }, 300);
    } else {
      // Addwishlist(Productyyy);

      // useAddWhishlist(Productyyy);
      whishlistadd.mutate(Productyyy);
      setWishList((wishList) => !wishList);
      setAnimateWishlist(true);
      // Reset the animation after it completes (0.3s)
      setTimeout(() => {
        setAnimateWishlist(false);
      }, 300);
    }
  };
  return (
    <div>
      <div className="productSection m-5">
        <div className="productShowCase">
          <div className="productGallery">
            <div className="productThumb">
              {/* {images.length > 0 && images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt=""
                  onClick={() => setCurrentImg(index)}
                />
              ))} */}
            </div>
            <div className="productFullImg">
              {/* <img
                src={IMAGE_URL_BASE_PATH + ProductD?.IM_PicturePath}
                alt=""
              /> */}
              <img
                src={
                  typeof ProductD?.IM_PicturePath == "string"
                    ? IMAGE_URL_BASE_PATH + ProductD?.IM_PicturePath
                    : NoImage
                }
                alt=""
              />
              {/* <div className="buttonsGroup">
                <button onClick={prevImg} className="directionBtn">
                  <GoChevronLeft size={18} />
                </button>
                <button onClick={nextImg} className="directionBtn">
                  <GoChevronRight size={18} />
                </button>
              </div> */}
            </div>
          </div>
          <div className="productDetails ">
            <div className="productBreadcrumb">
              <div className="breadcrumbLink">
                <Link to="/">Home</Link>&nbsp;/&nbsp;
                <Link to="/shop">The Shop</Link>
              </div>
              {/* Previous and Next links can be updated dynamically */}
              <div className="prevNextLink">
                <Link to={`/product/${parseInt(prid) - 1}`}>
                  <GoChevronLeft />
                  <p>Prev</p>
                </Link>
                <Link to={`/product/${parseInt(prid) + 1}`}>
                  <p>Next</p>
                  <GoChevronRight />
                </Link>
              </div>
            </div>
            <div className="productName">
              <h1>{ProductD?.IM_ItemName} sfdg</h1>
            </div>
            <div className="productRating">
              {/* Example rendering of stars based on rating */}
              {/* {renderStars(rating)} */}
              {/* <p>{reviews.length} reviews</p> */}
            </div>
            <div className="productPrice">
              <div className="sdProductPrice">
                {ProductD?.IM_MRP && !isEmpty(ProductD?.IM_MRP) ? (
                  <h3>
                    <span className="sdProductOriginalPrice">
                      {CURRENCY_SYMBOLS} {ProductD?.IM_MRP}
                    </span>
                    <span className="sdProductFinalPrice">
                      {CURRENCY_SYMBOLS} {ProductD?.IM_SALESPRICE}
                    </span>
                  </h3>
                ) : (
                  <h3 className="sdProductFinalPrice">
                    {CURRENCY_SYMBOLS} {ProductD?.IM_SALESPRICE}
                  </h3>
                )}
              </div>
            </div>
            <div className="productDescription">
              <p>{!isEmpty(ProductD?.IM_ShortDesc)?ProductD?.IM_ShortDesc:''}</p>
              {ProductD?.TotalStock === 0 ? (
                <p className="outOfStock">
                  "Out of stock, but not out of your dreams!"
                </p>
              ) : (
                ""
              )}
            </div>
            {/* Additional product details */}
            <div className="productTags">
              {/* <p>
                <span>SKU: </span>{sku}
              </p>
              <p>
                <span>CATEGORIES: </span>{category}
              </p> */}
              <p>{/* <span>TAGS: </span>{tags.join(", ")} */}</p>
            </div>
            {ProductD?.TotalStock === 0 ? (
              <div>
                <div className="productCartQuantity ">
                  <div className="productCartBtn ">
                    
                    <button
                      style={{ cursor: "no-drop", backgroundColor: "grey" }}
                      disabled={true}
                      onClick={() => handleAddToCart(ProductD)}
                    >
                      Add to Cart 
                    </button>
                  </div>
                  <div className="productCartBtn">
                    <button
                      style={{ cursor: "no-drop", backgroundColor: "grey" }}
                      disabled={true}
                      onClick={() => handleBuyNowd(ProductD)}
                    >
                      BuY Now
                      <span style={{ color: "transparent" }}>.....</span>
                    </button>
                  </div>
                </div>

                <div className="productWishShare">
                  <div className="productWishList">
                    <button
                      style={{ cursor: "no-drop" }}
                      disabled={true}
                      onClick={() => handleWishlistClick(ProductD)}
                    >
                      <FiHeart
                        className={`wishlist-icon ${
                          animateWishlist ? "animate-wishlist" : ""
                        }`}
                        style={{
                          color: wishList ? "red" : "#767676",
                          cursor: "pointer",
                        }}
                        size={17}
                      />
                      <p>Add to Wishlist</p>
                    </button>
                  </div>
                  <div
                    style={{ cursor: "no-drop" }}
                    disabled={true}
                    className="productShare"
                  >
                    <PiShareNetworkLight size={22} />
                    <p>Share</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="productCartQuantity ">
                  <div className="productCartBtn">
                    <button
                      // style={{cursor:"no-drop",backgroundColor:"grey"}} disabled={true}
                      onClick={() => handleAddToCart(ProductD)}
                    >
                      Add to Cart
                    </button>
                  </div>
                  <div className="productCartBtn    ">
                    <button
                      //  style={{cursor:"no-drop",backgroundColor:"grey"}} disabled={true}
                      onClick={() => handleBuyNowd(ProductD)}
                    >
                      BuY Now
                      <span style={{ color: "transparent" }}>.....</span>
                    </button>
                  </div>
                </div>

                <div className="productWishShare">
                  <div className="productWishList">
                    <button
                      //  style={{cursor:"no-drop"}} disabled={true}
                      onClick={() => handleWishlistClick(ProductD)}
                    >
                      <FiHeart
                        className={`wishlist-icon ${
                          animateWishlist ? "animate-wishlist" : ""
                        }`}
                        style={{
                          color: wishList ? "red" : "#767676",
                          cursor: "pointer",
                        }}
                        size={17}
                      />
                      <p>Add to Wishlist</p>
                    </button>
                  </div>
                  <div className="productShare">
                    <PiShareNetworkLight size={22} />
                    <p>Share</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AdditionalInfo
        title={"Test"}
        description={!isEmpty( ProductD?.IM_LongDesc) ?  ProductD?.IM_LongDesc :''}
        tags={[]}
        rating={[]}
        sku={[]}
        reviews={[]}
        weight={[]}
        dimensions={[]}
        availabilityStatus={[]}
        shippingInformation={[]}
        warrantyInformation={[]}
        stock={[]}
      />
    </div>
  );
};

export default ProductDetails;
