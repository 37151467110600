// shippingAddressSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {userData,LocalSet,LocalGet} from '../../Api/Local';
import {fetchAddressData} from '../../Api/Address';
// Initial state for shipping address
const persistedAddress = JSON.parse(localStorage.getItem("hyaddr")) || null;

var initialState = persistedAddress ? persistedAddress : {
    "Shipment_Id": '',
    "Shipment_AddressLine1": "",
    "Shipment_AddressLine2": "",
    "Shipment_Landmark": "",
    "Shipment_Country": "",
    "Shipment_State": "",
    "Shipment_City": "",
    "Shipment_Pincode": "",
    "Shipment_Address_Name": "",
    "Shipment_Notes": "",
    "Shipment_default_address": '',
    "Shipment_Receiver_No": "",
    "Shipment_Receiver_Name": "",
  }
// Create the slice
const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState,
  reducers: {
    setFullAddress(state, action) {
      console.log('action.payload',action.payload);
      state.state = action.payload;
      LocalSet('hyaddr',JSON.stringify(action.payload));
    },
    getDefaultAddress(state, action){
          
      const persistedUser = userData();
      console.log('persistedUser_persistedUser',persistedUser);
      if (persistedUser) {
        (async () => {
          try {

           
            
            const Addressdata = await fetchAddressData();
       
            const activeAddress=Addressdata.filter((item)=>item.Shipment_default_address==true);
            console.log('Addressdata','Fil',activeAddress[0].Shipment_Id);
            state.Shipment_Id=activeAddress[0].Shipment_Id;
            // console.log('Addressdata',Addressdata[]);
            // if (data['message']) {
            //   showToast(data['message'], 'success');
            // } else {
            //   showToast("Failed To add Cart", 'error');
            // }
          } catch (error) {
            console.error(error);
            // showToast("Something went wrong", 'error');
          }
        })();
      } else {
    
    
        // showToast("Added To Cart", 'success');
      }

    },
    setCity(state, action) {
      state.Shipment_City = action.payload;
      let locald=LocalGet('hyaddr');
      console.log('hyaddr',locald);
      if(locald){
        locald['Shipment_City']=action.payload;
        LocalSet('hyaddr',JSON.stringify(locald));
      }else{
        console.log('hyaddr',initialState);
        initialState = { ...initialState, Shipment_City: action.payload };
        LocalSet('hyaddr',JSON.stringify(initialState));
      }

    },
    setState(state, action) { 
      state.Shipment_State = action.payload;
      let locald=LocalGet('hyaddr');
      if(locald){
        locald['Shipment_State']=state.Shipment_State;
        LocalSet('hyaddr',JSON.stringify(locald));
      }else{
        locald=initialState;
        locald['Shipment_State']=state.Shipment_State;
        LocalSet('hyaddr',JSON.stringify(locald));
      }
   
    },
    setZip(state, action) {
      state.Shipment_Pincode = action.payload;
      let locald=LocalGet('hyaddr');
      if(locald){
        locald['Shipment_Pincode']=action.payload;
        LocalSet('hyaddr',JSON.stringify(locald));
      }else{
        locald=initialState;
        locald['Shipment_Pincode']=action.payload;
        LocalSet('hyaddr',JSON.stringify(locald));
      }
 
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    resetShippingAddress(state) {
      return initialState;
    },
  },
});

// Export the reducer and the actions
export const {
    setFullAddress,
  setCity,
  setState,
  setZip,
  setCountry,
  resetShippingAddress,
  getDefaultAddress
} = shippingAddressSlice.actions;

export const  getFullAddress = (state) => state.shippingAddress;
  
export default shippingAddressSlice.reducer;