import { useState, useEffect, React } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../Components/Toast/ShowToast";
import store from "../../App/store";
import { showLoader, hideLoader } from "../../Features/Loader/Loader";
import { addwish } from "../../Api/WishList";
import { removewishlisttt } from "../../Api/WishList";
import { userDataGet } from "../../Api/Local";
import { getCount } from "./WishSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useAddWhishlist = () => {
  const useQueryClientd = useQueryClient();

  return useMutation({
    mutationFn: addwish,
    onMutate: () => {
      store.dispatch(showLoader());
    },
    onSuccess: async (ddata) => {

      if (ddata.message) {
        store.dispatch(hideLoader());

        await useQueryClientd.invalidateQueries({ queryKey: ["wishListData"] });
        // await useQueryClientd.invalidateQueries({ queryKey: ["groupdproducts"] });

        showToast("Added Successfully", "success");
      } else {
        showToast("Something went wrong", "error");
      }
    },
    onError: (err) => {
      console.log("error", err.response.data);
    },

    onSettled: (data, error) => {},
  });
};

const useRemoveWhishlist = () => {
  const useQueryClientd = useQueryClient();

  return useMutation({
    mutationFn: removewishlisttt,
    onMutate: () => {
      // showToast("Login Process Started", 'success');
      store.dispatch(showLoader());
    },
    onSuccess: async (ddata) => {
      if (ddata.message) {
        store.dispatch(hideLoader());

        showToast("Removed Successfully", "success");
        await useQueryClientd.invalidateQueries({ queryKey: ["wishListData"] });
      } else {
        showToast("Something went wrong", "error");
      }
    },
    onError: (err) => {
      console.log("error", err.response.data);
    },

    onSettled: (data, error) => {},
  });
};

const Addwishlist = (product) => {
  console.log("wishlistdata", product);

  try {
  } catch (e) {}
};

const removewishlist = (product, EcomID) => {
  // console.log("wishlistaaddata", product);
  // store.dispatch(showLoader());

  // const removingProduct = {
  //   "Ecom_WL_Id": EcomID,
  //    "ecom_WL_CustId":  userDataGet("user_id"),
  //    "ecom_WL_IM_Id": product.IM_ID,
  //    "ecom_WL_CreatedBy": "string",
  //    "ecom_WL_CreatedAt": "2024-09-02T06:36:26.993Z",
  //    "ecom_WL_UpdatedBy": "string",
  //    "ecom_WL_UpdatedAt": "2024-09-02T06:36:26.993Z"
  //  };

  try {
    // removewishlisttt(removingProduct);
    //       store.dispatch(getCount());
    // store.dispatch(hideLoader());
    // showToast("removed Successfully", "success");
  } catch (e) {
    // console.log("ADD TO CART", e);
    // showToast("Something went wrong", "error");
  }
};
export { Addwishlist, removewishlist, useAddWhishlist, useRemoveWhishlist };
